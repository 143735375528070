import React from "react";

import InputField from "../../InputField";
import TextArea from "../../TextArea";
import NavArrow from "../NavArrow";
import Flexbox from "../../Flexbox";
import GeographicArea from "../../GeographicArea";
import GeographicAreaDetailed from "../../GeographicAreaDetailed";
import RadioButtons from "../../RadioButtons";
import Checkbox from "../../Checkbox";
import IconSelection from "../../IconSelection";
import TextStep from "../../TextStep";
import Dropdown from "../../Dropdown";
import Button from "../../Button";
import FinalStep from "../FinalStep";
import TiledInput from '../../TiledInput'
import { GridWrapper } from "./Step.styles";

const Step = ({
  setDisabled,
  geographicAreaDetailedOnChange,
  geographicAreaOnChange,
  iconSelectionOnChange,
  backStep,
  nextStep,
  step,
  handleChange,
  onKeyDown,
  onKeyUp,
  stepOrder,
  steps,
  selectedStates,
  className,
  onInputFieldChange,
  tiledInputOnChange
}) => {
  const { name, value, placeholder, type } = step;
  if (step.name === "submit")
    return (
      <Flexbox column>
        <h1>Submitting...</h1>
      </Flexbox>
    );
  if (step.name === "geographicArea")
    return (
      <GeographicArea
        stateValues={value}
        className={className}
        handleChange={function (_val, svgPathSelectedState = null) {
          // step.onChange(_val, stepOrder, steps, svgPathSelectedState);
          geographicAreaOnChange(_val, svgPathSelectedState);
        }}
      ></GeographicArea>
    );
  if (step.name === "geographicAreaDetailed")
    return (
      <GeographicAreaDetailed
        {...step}
        step={step}
        svgPath={step["svgPath"]}
        className={className}
        counties={value}
        initialValue={step["initialValue"]}
        selectedStates={selectedStates}
        setDisabled={setDisabled}
        setCounties={function (_val) {
          // step.onChange(_val);
          geographicAreaDetailedOnChange(_val)
        }}
      ></GeographicAreaDetailed>
    );
  if (step.name === "finalStep") {
    return (
      <FinalStep
        {...step}
        step={step}
        className={className}
        handleChange={handleChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        nextStep={nextStep}
        backStep={backStep}
      />
    );
  }
  if (type && type === "radio")
    return (
      <RadioButtons
        options={step.enum}
        onClick={step.onChange}
        selected={step.value}
        {...step}
        listWidth="100%"
      ></RadioButtons>
    );
  if (type && type === "TiledInput")
    return (
      <TiledInput
        options={step.enum}
        onClick={step.onChange}
        selected={step.value}
        items={step['value']}
        inputId={`${step['name']}-inputField`}
        setItems={tiledInputOnChange}
        placeHolder="Press 'Enter' between keywords"
        validatorRemoveItem={(input) => {
          console.log("input is: ", input, step['value'])
          // tiledInputOnChange([{ id: input, value: input }])
          console.log("should remove:", step['value'].find(it => it['id'] === input) !== undefined)
          return (step['value'].find(it => it['id'] === input) !== undefined || input === '')
        }}
        handleChange={(input) => {
          console.log("[x] input is: ", input, step['value'])
          tiledInputOnChange([...step['value'], { id: input, value: input }])
          return step['value'].find(it => it['id'] !== input)
        }}
        {...step}
        listWidth="100%"
      />
    );
  if (type && type === "dropdown")
    return (
      <Dropdown
        justifyContent="start"
        showHeader={true}
        headerFontSize="16px"
        showArrow={true}
        maxHeight={4 * 49 + "px"}
        mobileMaxHeight="100vh"
        mobileHeight="100vh"
        // boxShadow="0 0 5px rgba(255,255,255,0.5)"
        dropdownListMarginTop="20px"
        // background="#fff"
        border="none"
        headerBorder="1px solid #fff"
        headerHeight="49px"
        itemHeight="49px"
        textAlign="left"
        options={step.enum}
        handleStateChange={step.onChange}
        currSelection={step.value}
        {...step}
        handleMenuStateInternally={true}
        listWidth="100%"
      />
    );
  if (type && type === "iconSelection")
    return (
      <IconSelection
        options={step.enum}
        label={step.getLabel}
        className={className}
        onInputFieldChange={onInputFieldChange}
        {...step}
        onChange={
          step["multiSelect"]
            ? (val, currValue, steps, option) => {
              iconSelectionOnChange(step['id'], val)
            }
            : (option) => iconSelectionOnChange(step["id"], option)
        }
      />
    );
  if (type === "text") return <TextStep {...step} className={className} />;
  if (type && type === "checkbox")
    return (
      <Checkbox
        checked={step.phoneCallRequested}
        onCheck={() => step.onChange(step.checked)}
        text={step.checkboxText}
      />
    );
  if (type && type === "textArea")
    return (
      <TextArea
        hideLabel={step.labelInternal !== undefined ? !step['labelInternal'] : true}
        onChange={handleChange(`${name}`)}
        focusOutline="1px solid var(--formBlue1)"
        padding="10px 35px 10px 20px"
        fontSize="16px"
        useUnderlineTheme={true}
        {...step}
      />
    );
  return (
    <InputField
      className={className}
      id={step["id"]}
      type={type ? type : name === "email" ? "email" : "text"}
      onChange={handleChange(`${name}`)}
      name={name}
      placeholder={placeholder}
      marginBottom={"20px"}
      value={value}
      width="100%"
      onKeyDown={onKeyDown(`${name}`)}
      onKeyUp={onKeyUp}
      uppercaseOff={true}
      maxWidth={step["maxWidth"]}
      useUnderlineTheme={true}
    // {...step}
    />
  );
};

export default Step;
