import React, { useState } from 'react'
import InputField from '../InputField'
import TextArea from '../TextArea'
import TiledInput from '../TiledInput'
import GeographicAreaDetailed from '../GeographicAreaDetailed'
import Dropdown from '../Dropdown'
import Checkbox from '../Checkbox'
import RadioButtons from '../RadioButtons'
import MultiSelect from '../MultiSelect'
import Button from '../ButtonHome'
import Button2 from '../Button'
import styled from 'styled-components'
import GlassButton from '../GlassButton'
import IconSelection from '../IconSelection'
import CheckmarkValidate from '../CheckmarkValidate'
import Flexbox from '../Flexbox'
import PhoneInput from '../PhoneInput'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import privacyLock from '../../images/privacyLock@2x.png'
import RadixDropdown from '../RadixDropdown'

const H1 = styled.h1`
text-align: center;
margin: 0 auto;
font-weight: 400;
font-size: 0.68rem;
`;
const H2 = styled.h2`
text-align: center;
margin: 0 auto;
font-weight: 600;
font-size: 1.1rem;
`;
const H3 = styled.h3`
text-align: center;
margin: 0 auto;
font-weight: 500;
font-size: 0.8rem;
@media screen and (max-width:768px) {
font-size: 14px;
}
`;
const H4 = styled.h4`
text-align: center;
margin: 0 auto;
font-weight: 400;
font-size: 12px;
`;

export const Container = styled.div`
display: grid;
grid-auto-flow: row;
grid-template-columns: 2fr;
position: relative;
grid-auto-rows: min-content;
grid-template-rows: ${({ rows }) => rows};
gap: 10px;
width: 100%;
margin: auto;
background: rgba(255,255,255,0.05);
padding: 30px;
justify-items: start;
height: 100%;
max-height: 100vh;
overflow: auto;
border-top: 1px solid rgba(255,255,255,0.5);
border-left: 1px solid rgba(255,255,255,0.5);
border-right: 1px solid rgba(255,255,255,0.5);
border-bottom: 1px solid rgba(255,255,255,0.5);
    box-shadow: 
    5px 5px 10px -5px rgba(0, 11, 92, 0.35), 
    inset 3px 3px 6px -3px rgba(var(--formBlueRaw),0.95), 
    inset -3px -3px 6px -3px rgba(158,0,194,0.95), 
    3px 3px 6px -3px rgba(158,0,194,0.95),
    -3px -3px 6px -3px rgba(var(--formBlueRaw),0.95);
/* border-radius: 20px; */
grid-area: content;
height: min-content;
min-height: 500px;
max-width: 600px;
border-radius: 10px;
/* max-height: 200px; */
${({ isLastStep }) => {
        if (!isLastStep)
            return
        else {
            return `
                display: flex;
                flex-direction: column;
                justify-content: start;
                border: none;
                // box-shadow: none;
                // background: none;
                // padding: 0;
                // border-radius: 0;
                `;
        }
    }}
    
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    height: 100%;
}
`;

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    text-align: left;
`;


const StepGrid = styled.div`
display: grid;
gap: ${({ gap }) => gap};
grid-template-columns: ${({ cols }) => cols};
grid-template-rows: ${({ rows }) => rows};
grid-auto-flow: ${({ autoFlow }) => autoFlow};
grid-auto-rows: ${({ autoRows }) => autoRows};
grid-auto-columns: ${({ autoCols }) => autoCols};
width: ${({ width }) => width};
justify-content: center;
align-items: center;
`;

const Field = ({ shouldShowValidators, type, name, id, shouldShow, value, setShowDropdown, field, setFormData, onChange, handleStateChange, geoHookProps, fields, radioButtonOnClick, multiSelect, checkOnChange, iconSelectionOnChange, ...props }) => {
    if (type === 'checkbox') {
        return (
            <Flexbox style={{ position: 'relative' }}>
                <Checkbox checkOnChange={() => { checkOnChange(id) }} {...field} />
                {(!field['isValid'] && shouldShowValidators) && <CheckmarkValidate margin="auto 0" right="-15px" />}
            </Flexbox>
        )
    }
    if (['phoneNumber'].includes(name)) {
        return (<PhoneInput {...props}
            useUnderlineTheme={true}
            padding={type === 'price' ? "0 40px 0 25px" : "0 40px 0 20px"}
            {...field}
            label={undefined}
            showValidationCheckmark={!field['isValid'] && shouldShowValidators}
            value={value} setValue={(e) => {
                onChange(id, e, fields)
            }} />)
    }
    if (['input', 'number', 'price'].includes(type)) {
        return (<InputField {...props}
            useUnderlineTheme={true}
            padding={type === 'price' ? "0 40px 0 25px" : "0 40px 0 20px"}
            {...field}
            label={undefined}
            showValidationCheckmark={!field['isValid'] && shouldShowValidators}
            value={value} onChange={(e) => {
                onChange(id, e, fields)

            }} />)
    }
    if (type === 'textArea') {
        return (<TextArea {...field}
            useUnderlineTheme={true} onChange={(e) => {
                onChange(id, e, fields)
            }} label={undefined} />)
    }
    if (type === 'multiselect') {
        return (<MultiSelect {...field} onSelect={(v) => multiSelect(id, v)} />)
    }
    if (type === 'iconSelection') {
        return (<IconSelection {...field} label={undefined} onChange={
            field['multiSelect'] ? (val, currValue, steps, option) => iconSelectionOnChange(field['id'], option, field, steps) : (option) => iconSelectionOnChange(field["id"], option)
        } />)
    }
    if (type === 'radio') {
        return (<RadioButtons
                    dropdownRef={null}
                    listContainerRef={null}
                    onClick={(v) => radioButtonOnClick(id, v)}
                    headerFontSize="16px"
                    fontSize="16px"
                    headerHeight="49px"
                    itemHeight="49px"
                    maxHeight={4 * 49 + 'px'}
                    // dropdownListMarginTop={"10px"}
                    marginTop="0px"
                    {...field}
                    showHeader={true}
                    showArrow={true}
                    name={'Suggested Tags'}
                    marginBottom={'1rem'}
                    border="none"
                    headerBgd={'var(--inputBackground)'}
                    boxShadow={" 0 0 4px rgba(var(--formBlue2Raw),0.5)"}
                    id={'suggested-tags'}
                    setValidContinue={() => { }}
                    justifyContent="start"
                    textAlign="left"
                />)
    }
    if (name === 'geographicArea') {
        return (<TiledInput
            key={"geographicArea"}
            labelTextAlign="left"
            labelFontSize="14px"
            // placeHolder={toTitleCase(formData['fields'][fieldId]['placeholder'])}
            {...field}
            {...geoHookProps}
            display="flex"
            padding="0 40px 0 20px"
            mobileMaxWidth="100%"
            maxWidth="unset"
            focusOutline="none"
            boxShadow="var(--defaultInputBoxShadow)"
            background={'var(--underLineThemeBgd)'}
            outline="none"
            border="none"
            inputBorder="none"
            focusProps={{ outline: 'none' }}
            // isValid={formData['fields'][fieldId]['isValid']}
            useUnderlineTheme={true}
            multiTermEnabled={false}
            flexDirection="column"
            // showValidationCheckmark={(shouldShowValidators && fields[fieldId]['shouldShow'] !== undefined)}
            fontSize="16px"
            width="100%"></TiledInput>)
                    // return (
        //     <AutoComplete
        //         className="p-autocomplete"
        //         items={field['items']}
        //         completeMethod={(e) => {
        //             console.log(`[s]::${e.query}`)
        //             // geoHookProps.getSuggestions(s, () => { })
        //         }}
        //         value={field['value']}
        //         setValue={(v) => {
        //             console.log(`[v]::${v}`)
        //             geoHookProps.setItems([{ value: v }])
        //         }} />
        // )
    }
    if (name === 'geographicAreaDetailed') {
        let geoDetailedEnabled = field['isEnabled'] || false
        return (<GeographicAreaDetailed
            inputOnly={true}
            key={"geographicArea"}
            labelTextAlign="left"
            labelFontSize="14px"
            disabled={!geoDetailedEnabled}
            geoClassName={geoDetailedEnabled ? "" : "disabled"}
            setDisabled={() => { }}
            {...field}
            {...geoHookProps}
            counties={value}
            onBlur={() => (geoHookProps['onBlurGeoDetailed'])(fields)}
            display="flex"
            padding="0 40px 0 20px"
            mobileMaxWidth="100%"
            maxWidth="unset"
            focusOutline="none"
            boxShadow="var(--defaultInputBoxShadow)"
            background={'var(--underLineThemeBgd)'}
            outline="none"
            border="none"
            inputBorder="none"
            focusProps={{ outline: 'none' }}
            // isValid={formData['fields'][fieldId]['isValid']}
            useUnderlineTheme={true}
            multiTermEnabled={false}
            flexDirection="column"
            inputFieldProps={{
                cursorDisabled: 'not-allowed',
                backgroundDisabled: '#030024',
                boxShadow: "var(--defaultInputBoxShadow)",
                background: 'var(--underLineThemeBgd)',
                outline: "none",
                border: "none",
                inputBorder: "none",
                mobileMaxWidth: '100%',
                focusProps: { outline: 'none' },
                // showValidationCheckmark: (shouldShowValidators && !fields[fieldId]['isValid']) || fields[fieldId]['shouldShow']
            }}
            placeholder="xxx"
            fontSize="16px"
            width="100%"></GeographicAreaDetailed>)
    }
    if (type === 'dropdown') {
        return (<RadixDropdown {...props} {...field} onValueChange={(e) => handleStateChange(e, field)}

            showValidationCheckmark={!field['isValid'] && shouldShowValidators}
        />)
        return (
            <Dropdown
                dropdownRef={null}
                listContainerRef={null}
                headerFontSize="16px"
                headerHeight="49px"
                itemHeight="49px"
                maxHeight={4 * 49 + 'px'}
                dropdownListMarginTop={"10px"}
                {...props}
                showValidationCheckmark={!field['isValid'] && shouldShowValidators}
                showHeader={true}
                showArrow={true}
                name={'Suggested Tags'}
                marginBottom={'1rem'}
                border="none"
                headerBgd={'var(--inputBackground)'}
                handleMenuStateInternally={false}
                menuState={field['showDropdown']}
                setMenuState={() => setShowDropdown({ ...field, field: id })}
                handleStateChange={(e) => handleStateChange(e, field)}
                // handleStateChange={(e) => {
                //     let val = e.target.getAttribute('value')
                //     setFormData(prev => ({
                //         ...prev,
                //         fields: {
                //             ...prev['fields'],
                //             [field['id']]: {
                //                 ...prev['fields'][field['id']],
                //                 value: val,
                //                 showDropdown: false,
                //                 isValid: field['isValid'] === undefined ? undefined : val !== "--"
                //             },
                //         }
                //     }))
                // }}
                currSelection={value ? value : null}
                valueX={value}
                boxShadow={" 0 0 4px rgba(var(--formBlue2Raw),0.95)"}
                id={'suggested-tags'}
                setValidContinue={() => { }}
                justifyContent="start"
                textAlign="left" />
        )
    }
}

const LabelComp = ({ field, name, label, isValid, shouldShowValidators, showValidAtLabelLevel = false }) => {
    if (!showValidAtLabelLevel)
        return (<Label htmlFor={name}>{label}</Label>)
    return (<Flexbox row><Label htmlFor={name}>{label}</Label>{(!field['isValid'] && shouldShowValidators && showValidAtLabelLevel) && <CheckmarkValidate margin="-5px 0 0 10px" position="relative" />}</Flexbox>)

}

const FieldAndLabel = ({ field, fields, shouldShowValidators, ...props }) => {
    return (<>
        {field['label'] &&
            <LabelComp {...field} field={field} shouldShowValidators={shouldShowValidators} />
        }
        <Field {...field} field={field} shouldShowValidators={shouldShowValidators} {...props} fields={fields} />
    </>)
}

const MultiField = ({ shouldShowValidators, activeFields, fields, tertiaryFieldOrder, gridTemp, ...props }) => {
    const gridDimensions = {
        rows: tertiaryFieldOrder ? tertiaryFieldOrder.length : activeFields.length,
        cols: tertiaryFieldOrder ? tertiaryFieldOrder.reduce((a, b) => {
            if (Array.isArray(b))
                if (b.length > a)
                    a = b.length
            return a
        }, 1) : 1
    }
    // const { rows, cols } = gridDimensions
    return (
        <>
            {
                (activeFields).map(field => {
                    // if (!Array.isArray(field))
                    const _field = fields[field]
                    return (<FieldAndLabel key={field} fields={fields} shouldShowValidators={shouldShowValidators} field={_field} {...props} />)
                })
            }
        </>)
}

const SizedLabel = ({ type, children }) => {
    switch (type) {
        case "h3":
            return <H3>{children}</H3>;
        case "h2":
            return <H2>{children}</H2>;
        case "h1":
            return <H1>{children}</H1>;
        case "h4":
            return <H4>{children}</H4>;
        default:
            return <>{children}</>;
    }
}

const StepLabel = ({ label, id, type }) => {
    return (
        <><SizedLabel type={type} >{label}</SizedLabel>
        </>)
}

const Step = ({ active, labels, fields, isFirstStep, isLastStep, nextStep, prevStep, ...props }) => {
    const { isValid, shouldShowValidators, toast, setToastOpen } = props
    return (
        <>
            <Container
                className={isLastStep ? "isLastStepContainer" : undefined}
                isLastStep={isLastStep}
                isFirstStep={isFirstStep}
                rows={!isLastStep ? undefined : `repeat(7,min-content) 1fr`}
                {...props}
            >
                {(typeof (active) === 'object' && Array.isArray(active['labels'])) &&
                    active['labels'].map(labelName => {
                        let label = labels[labelName]
                        return <StepLabel {...label} />
                    })
                }
                {(typeof (active) === 'object' && Array.isArray(active['fields'])) &&
                    <MultiField activeFields={active['fields']} fields={fields} {...props}
                        tertiaryFieldOrder={active['tertiaryFieldOrder']}
                        gridTemp={active['gridTemp']}
                    />}
                {(typeof (active) === 'string') &&
                    <>
                        <LabelComp {...fields[active]} field={fields[active]} name={fields[active].name} shouldShowValidators={shouldShowValidators}/>
                        <Field {...fields[active]} field={fields[active]} fields={fields} {...props} />
                    </>
                }
                {isLastStep &&
                    <StepGrid cols="1fr" rows="min-content 12px min-content" gap="10px" width="100%" >

                        {/* <Button text="Submit" width="100%" borderRadius="30px" height="60px" onClick={nextStep} /> */}
                        <GlassButton text="Get Case Value"
                            // className="dark" 
                            width="100%" borderRadius="30px" height="60px" onClick={nextStep} />
                        <h5 style={{ margin: '0' }}>
                            {(shouldShowValidators && !isValid) && <Flexbox justifyContent="center" width="100%" margin="0 auto"><span style={{ marginRight: '5px', color: '#ff0a0a', fontSize: '10px' }}>
                                Please complete all required fields marked with a </span><CheckmarkValidate position="relative" right="unset" isValid={false} top="unset" /></Flexbox>}
                            {(toast?.open) && <Flexbox justifyContent="center" width="100%" margin="0 auto"><span style={{ marginRight: '5px', color: '#ff0a0a', fontSize: '12px' }}>
                                {toast?.description} </span></Flexbox>}
                        </h5>
                        <GlassButton
                            className="dark"
                            text={<span style={{ transform: 'rotate(180deg)', display: 'block', opacity: '0.5' }}>➜</span>} width="30px" borderRadius="30px" height="30px" onClick={prevStep}
                            background="rgba(0,0,0,0)"
                            colorHover="#fff"
                            borderHover="1px solid #fff"
                            border="1px solid #fff" bgdHover="rgba(255,255,255,0.1)" />

                    </StepGrid>
                }
            </Container>
        </>
    )
}

export default Step