import React from 'react'
import Checkbox from '../Checkbox'
import Flexbox from '../Flexbox'
import styled from 'styled-components'

const GridContainer = styled.div`
display: grid;
width: 100%;
grid-template-columns: ${({ gCol = "repeat( auto-fit, minmax(min-content, 50%) )" }) => gCol};
justify-content: start;
grid-auto-flow: row;
grid-auto-rows: min-content;
`;
const MultiSelect = ({ gCol, options, groupedOptions, onSelect, value = [], ...props }) => {
    return (
        <>

            {
                options &&
                <Flexbox column maxHeight="350px" flexWrap="wrap" mobileMaxHeight="unset">
                    {options.map((it, index) => <Checkbox {...it} key={index + 1} text={it} minHeight="unset"
                        fontSize="16px"
                        margin="5px 20px 5px 0"
                        checkOnChange={(e) => {
                            // console.log(e.target.value)
                            onSelect(it)
                        }} checked={value.includes(it)} />)}</Flexbox>
            }
            {
                groupedOptions &&
                Object.keys(groupedOptions).map(key => {
                    const { options: _options, label } = groupedOptions[key]
                    return (
                        <>
                            <h4>{label}</h4>
                            <GridContainer gCol={gCol}>
                                {_options.map((it, index) => <Checkbox {...it} key={index + 1} text={it} minHeight="unset"
                                    fontSize="16px"
                                    margin="5px 20px 5px 0"
                                    checkOnChange={(e) => {
                                        // console.log(e.target.value)
                                        onSelect(it)
                                    }} checked={value.includes(it)} />)}
                            </GridContainer>
                        </>)
                })
            }

        </>
    )
}

export default MultiSelect