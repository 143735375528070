import React, { useState, useEffect, useRef } from 'react'
import InputField from '../InputField'
import Tag from '../Tag'
import SearchTermHints from '../SearchTermHints'
import Flexbox from '../Flexbox'

import { InnerContainer, TagItem, TagsInputContainer, Input } from './TiledInput.styles'

const TiledInput = ({
    data,
    tooltip,
    inputFieldProps,
    useOptionOnClick = false,
    validatorAddItem,
    handleChange,
    validatorRemoveItem,
    accessors = ['id', 'value'],
    placeHolder = 'Enter State(s)',
    onSelectFromDropdown,
    maxTerms = -1,
    multiTermEnabled = true,
    onChange,
    formatSelection,
    mobileMaxWidth="90%",
    getSuggestions,
    onFocus,
    inputPlaceHolderColor = 'var(--inputPlaceHolderColor)',
    showValidationCheckmark,
    onBlur,
    _input,
    _setInput,
    items,
    setItems,
    handleNoResponse,
    inputId,
    tagOnClick,
    disabled = false,
    className,
    width,
    maxWidth = "400px",
    background,
    additionalReset,
    shouldResetResponseMessage,
    tagBorder = '1px solid var(--primary)',
    isTyping,
    boxShadow: _boxShadow,
    onBlurProps = {
        outline: 'none',
        boxShadow: 'inset 0 0 5px rgb(0, 0, 0)'
    },
    color = 'white',
    focusProps = {
        outline: `1px solid var(--primary)`,
        boxShadow: 'inset 0 0 5px rgba(255, 255, 255, .5)'
    },
    responsePending,
    setDisabled
}) => {
    const parentContainer = useRef(null)
    const listContainerRef = useRef(null)
    const inputRef = useRef(null)
    const dropdownRef = useRef(null)
    const parsingMenu = useRef(false)
    const renderCounter = useRef(0)
    renderCounter.current = renderCounter.current + 1
    const [dropdownSelection, setDropdownSelection] = useState(null)
    const [suggestions, setSuggestions] = useState([])
    const [showDropdown, setShowDropdown] = useState(false)

    const resetSearchResults = () => {
        setSuggestions([])
        setShowDropdown(false)
        setDropdownSelection(-1)
        if (typeof (additionalReset) === 'function')
            additionalReset()
        if (shouldResetResponseMessage)
            shouldResetResponseMessage.current = true
    }

    const [focusProperties, setFocusProperties] = useState(onBlurProps)
    const { outline, boxShadow } = focusProperties


    const [isKeyReleased, setIsKeyReleased] = useState(false);
    const [input, setInput] = useState('')
    const _validatorAddItem = (trimmedInput, items) => {
        if (typeof (validatorAddItem) === 'function')
            return validatorAddItem(trimmedInput, items)
        return !items.find(el => el.id === trimmedInput) && trimmedInput.length
    }

    const _validatorRemoveItem = (trimmedInput, items) => {
        if (typeof (validatorRemoveItem) === 'function')
            return validatorRemoveItem(trimmedInput, items)
        return !items.find(el => el.id === trimmedInput) && !trimmedInput.length
    }

    const onKeyDown = (e) => {
        if (isTyping)
            isTyping.current = true
        const { key } = e;
        const trimmedInput = _input ? _input.trim() : input.trim();

        if ((key === 'ArrowDown' || key === 'ArrowUp')) {
            toggleThroughMenu(key === 'ArrowUp' ? -1 : 1)

        } else {
            parsingMenu.current = false
        }
        if (
            // (key === ',' || key === 'Enter')
            (key === 'Enter')
            && _validatorAddItem(trimmedInput, items)) {
            e.preventDefault();
            let _value = trimmedInput
            if (typeof (formatSelection) === 'function')
                _value = formatSelection(_value)
            if (multiTermEnabled) {
                if (useOptionOnClick) {
                    const htmlEl = document.querySelector(`#${_value.replaceAll(' ', '-').toLowerCase()}`)
                } else {
                    const _newItems = [...items, { value: _value, id: _value }].slice(0, maxTerms > 0 ? maxTerms : [...items, trimmedInput].length)
                    setItems(_newItems);
                }
            } else {
                if (!useOptionOnClick)
                    setItems([{ value: _value, id: _value }]);
                else {
                    const htmlEl = document.querySelector(`#${_value.replaceAll(' ', '-').toLowerCase()}`)
                    if (htmlEl && typeof (htmlEl['click']) === 'function')
                        htmlEl.click()
                    else
                        setItems({ value: _value, id: _value, innerText: _value });
                }

            }
            if (typeof (_setInput) === 'function')
                _setInput('')
            else
                setInput('');
            // setSuggestions([])
        }

        if (key === "Backspace" && isKeyReleased && _validatorRemoveItem(trimmedInput, items)) {
            const itemsCopy = [...items];
            const poppedTag = itemsCopy.pop();
            e.preventDefault();
            if (poppedTag) {

                setItems(itemsCopy.slice(0, maxTerms > 0 ? maxTerms : itemsCopy.length));
                handleChange(poppedTag.id)
                if (typeof (_setInput) === 'function')
                    _setInput(poppedTag.id || '')
                else
                    setInput(poppedTag.id || '');
                setSuggestions([])
            }
            // if (poppedTag) {

            // }
        }

        setIsKeyReleased(false);

    };

    const onKeyUp = () => {
        setIsKeyReleased(true);
    }

    const toggleThroughMenu = (dir) => {
        if (suggestions.length > 0) {
            const _suggestionsNoKeys = suggestions.filter(it => !it.isKey)
            let val = _suggestionsNoKeys.findIndex(it => it.innerText === dropdownSelection) + dir

            val =
                val >= _suggestionsNoKeys.length ? 0 :
                    val < 0 ? _suggestionsNoKeys.length - 1 :
                        val
            onToggleThroughMenu(_suggestionsNoKeys[val].innerText, val)
        }
    }


    const onToggleThroughMenu = (value, index) => {
        parsingMenu.current = true
        if (typeof (_setInput) === 'function')
            _setInput(value)
        else
            setInput(value)
        setDropdownSelection(value)
    }

    const handleLongTermsList = () => {
        if (parentContainer.current) {
            const { scrollWidth, offsetWidth } = parentContainer.current
            if (scrollWidth > offsetWidth)
                parentContainer.current.scrollLeft = parentContainer.current.scrollLeftMax

        }
    }



    const handleLongSuggestionsList = () => {
        if (listContainerRef.current) {
            const { scrollHeight, offsetHeight } = listContainerRef.current
            if (scrollHeight > offsetHeight)
                listContainerRef.current.scrollTop = listContainerRef.current.scrollTopMax

        }
    }

    const getInput = () => {
        if (typeof (_input) === 'string')
            return _input
        return input
    }

    useEffect(() => {
        if (typeof (onChange) === 'function') {
            onChange(items)

        }
        handleLongTermsList()
    }, [items])

    const shouldExecuteSearch = () => getInput().length > 0 && !parsingMenu.current
    
    const shouldResetSearchSuggestions = () => !parsingMenu.current || getInput().length === 0
    return (
        <Flexbox column relative width="100%" maxWidth={maxWidth} mobileMaxWidth={mobileMaxWidth} 
        >
            
            <TagsInputContainer 
            boxShadow={_boxShadow || boxShadow}
            className={className} 
                            {...inputFieldProps}
            outline={outline} maxWidth={maxWidth} background={background} inputBorder={focusProps['outline']}>
                <InnerContainer ref={parentContainer} 
                            >
                    {items.map((tag, index) => (
                        <Tag
                            border={tagBorder}
                            margin="1px"
                            color={'var(--tagInputColor)'}
                            key={tag.id || tag.value}
                            onClick={function () {
                                if (typeof (tagOnClick) === 'function') {
                                    tagOnClick(tag)
                                } else {
                                    _validatorRemoveItem(tag.value, items)
                                    const _newItems = [...items.filter(it => it.value !== tag.value)]
                                    setItems(_newItems)
                                }

                            }}
                            name={tag.value} />)
                    )}
                    {(multiTermEnabled || items.length === 0) &&
                        <InputField
                            title={tooltip}
                            id={inputId}
                            refId={inputRef}
                            className={className} 
                            {...inputFieldProps}
                            showValidationCheckmark={showValidationCheckmark}
                            onBlur={() => {
                                setFocusProperties(onBlurProps)
                                if (typeof (onBlur) === 'function') {
                                    const trimmedInput = getInput().trim();
                                    onBlur(trimmedInput)
                                    // setInput('')
                                }

                            }
                            }
                            background="transparent"
                            onFocus={() => {
                                {
                                    setFocusProperties(focusProps)
                                    if (typeof (onFocus) === 'function')
                                        onFocus()
                                }

                            }}
                            boxShadow="none"
                            focusOutline="none"
                            focusBorder="focusBorder"
                            outline="none"
                            border="none"
                            boxShadowFocus="none"
                            fontSize="16px"
                            minWidth="200px"
                            padding="0 5px"
                            type="text"
                            color="inherit"
                            disabled={disabled}
                            placeholder={placeHolder}
                            inputPlaceHolderColor={inputPlaceHolderColor}
                            onKeyDown={onKeyDown}
                            isTyping={isTyping}
                            onKeyUp={onKeyUp}
                            value={typeof (_input) === 'string' && typeof (_setInput) === 'function' ? _input : input}
                            onChange={(e) => {
                                // if (shouldResetResponseMessage)
                                //     shouldResetResponseMessage.current = true
                                // if (isTyping)
                                //     isTyping.current = true
                                if (typeof (_setInput) === 'function')
                                    _setInput(e.target.value)
                                else
                                    setInput(e.target.value)
                            }}
                        // onChange={(e) => setInput(e.target.value)} 
                        />}
                </InnerContainer>

            </TagsInputContainer>
            <SearchTermHints
                handleLongSuggestionsList={handleLongSuggestionsList}
                setDisabled={setDisabled}
                listContainerRef={listContainerRef}
                inputRef={inputRef}
                marginTop="calc(70px + 10px)"
                height="49px"
                minHeight="49px"
                dropdownRef={dropdownRef}
                trigger={input}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                currSelection={dropdownSelection}
                setDropdownSelection={setDropdownSelection}
                shouldResetResponseMessage={shouldResetResponseMessage}
                handleNoResponse={handleNoResponse}
                boxShadow="0 0 5px rgba(0,0,0,.25)"
                shouldExecuteSearch={shouldExecuteSearch}
                resetSearchResults={resetSearchResults}
                shouldResetSearchSuggestions={shouldResetSearchSuggestions}
                getAndFormatSearchResults={async () => await getSuggestions(getInput(), typeof ('_setInput') === 'function' ? _setInput : setInput)}
            />
        </Flexbox>
    )
}

export default TiledInput