import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Button = styled.button`
position: fixed;
bottom: 20px;
right: 50%;
height: 40px;
width: 25px;
border: 2px solid rgba(255,255,255,0.1);
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
transform: translateX(50%);
&.scroll-btn{
    visibility: hidden;
    &.visible{
        visibility: visible;
    }
    background: linear-gradient(
        45deg,
        rgba(84, 0, 158,0.25),
        rgba(84, 0, 158,0.25) 35%,
        rgba(84, 0, 158,0.75) 50%,
        rgba(84, 0, 158,0.25) 65%,
        rgba(84, 0, 158,0.25) 100%
    );
    box-shadow: inset 0 0 7px 3px rgba(0,0,0,0.5),  0px 0px 4px 1px rgba(0, 240, 212, 0.95);
}
`;

const ScrollButton = () => {
    // const [isVisible, setIsVisible] = useState(false);
    const [state, setState] = useState({
        isVisible: false,
        prevScrollPos: 0
    })
    const scrollingUp = useRef(false)
    const { isVisible, prevScrollPos } = state
    const scrollEl = document.querySelector('.bodyContainer')

    useEffect(() => {
        if (!scrollEl)
            return
        const handleScroll = () => {
            const currentScrollPos = scrollEl.scrollTop;
            setState((prev) => {
                const { prevScrollPos } = prev
                // console.log("current", currentScrollPos, prevScrollPos)
                if (currentScrollPos < 50 || prevScrollPos < 50)
                    return ({
                        prevScrollPos: currentScrollPos,
                        isVisible: false
                    })
                if (prevScrollPos === currentScrollPos)
                    return ({ ...prev })
                else
                    return ({
                        prevScrollPos: currentScrollPos,
                        isVisible: prevScrollPos > currentScrollPos
                    })
            });
        };


        scrollEl.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollEl)
                scrollEl.removeEventListener('scroll', handleScroll);
        }
    }, [scrollEl]);

    const handleClick = () => {
        if (scrollEl) {
            scrollingUp.current = true
            scrollEl.scrollTo({ top: 0, behavior: 'smooth' });
            setState((prev) => ({ ...prev, isVisible: false, prevScrollPos: 0 }))
        }
    };

    return (
        <Button
            className={`scroll-btn ${isVisible ? 'visible' : ''}`}
            onClick={handleClick}
        >
            ↑
        </Button>
    );
};

export default ScrollButton;