import React, { Suspense, useEffect, useState, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Footer from './Footer'
import PageContentContainer from './PageContentContainer'
import GlassButton from './GlassButton'
import Flexbox from './Flexbox'
import Form from './Form'
import Navigation from './Navigation'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'
import gavel from '../images/gavel.svg'
import crystalBall from '../images/crystalBall.svg'
import only2percent from '../images/only2percent.svg'
import calculator from '../images/calculator@2x.png'
import setCalc from '../images/setCalcLogo4.svg'
import GridItem from './GridItem'
import ScrollButton from './ScrollButton'
import calc from '../images/calculator.png'
import MiniForm from './MiniForm'
import data from '../images/dataFlowing.jpg'
import fullImage from '../images/plant.png'
import dumpster from '../images/dontScrapYourProjectWasteMoney.png'
import phone from '../images/phoneForm2.svg'
import AdobeStock_580101302_Preview from '../images/AdobeStock_580101302_Preview.jpeg'
import inspectCost from '../images/inspectTrueCost.png'

const H1 = styled.h1`
font-size: 3rem;
margin-bottom: 10px;
text-align: ${({ textAlign }) => textAlign};
text-shadow: 0 0 5px #000;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    font-size: 1.8rem;
}
`;

const SectionWithBgdImage = styled.div`
height: 100vh;
flex-shrink: 0;
width: 100%;
background-image: ${({ src }) => src ? `url(${src})` : undefined};
background-size: cover;
display: flex;
justify-content: end;
align-items: center;
position: relative;
margin: auto;
background-position: left;
padding: 1rem;
.textcontainer{
    border-radius: 0.25rem;
    backdrop-filter: blur(12px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    padding: 1rem;
    /* border: 1px solid rgba(255,255,255,0.2); */
    max-width: 650px;
}
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    flex-direction: column;
    justify-content: center;
}
`;

/*
<img src={fullImage} style={{
                        position: 'absolute',
                        height: 'auto',
                        width: '50vw',
                        left: '0',
                        flexShrink: '0'
                    }} />
*/

const PlantImage = styled.img`
position: absolute;
height: auto;
width: 50vw;
max-height: 60vh;
object-fit: contain;
left: 0;
flex-shrink: 0;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    position: relative;
}
`;

const H2 = styled.h2`
font-size: 1.5rem;
font-weight: 100;
max-width: ${({ maxWidth = "600px" }) => maxWidth};
text-align: ${({ textAlign }) => textAlign};

`;

const SectionContainer = styled.div`
height: 100%;
min-height: 100vh;
width: 95%;
position: relative;
display: flex;
justify-content: center;
/* padding: 20px 0; */
align-items: center;
flex-direction: ${({ column }) => column ? 'column' : undefined};
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
 flex-direction: column;
    /* position: relative; */
}
`;

const P = styled.p`
font-size: 1.5rem;
font-weight: 100;
max-width: ${({ maxWidth = "600px" }) => maxWidth};
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    font-size: 1.2rem;
}
`;

const CalcImage = styled.img`
grid-area: image;
width: 100%;
max-width: 500px;
height: auto;
margin: auto;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    max-width: 200px
}
`;

const Img = styled.img`
position: relative;
width: ${({ width = "223px" }) => width};
height: auto;
`;

const BgdComponent = styled.div`
display: flex;
flex-direction: column;
margin: 0 50px;
transition: .3s all ease-out;
align-items: flex-start;
height: 100vh;
width: 100%;
overflow: hidden;
position: absolute;
//column alignItems="start" margin="0 50px" height="100vh" width="100%" overflow="hidden" position="absolute"
//linear-gradient(black 50%, #00005c, #cc3d00, #ffe747)
background: var(--formBlue2);
background: linear-gradient(
        
        #000,
        #000 60%,
        #00005c 95%,
        #cc3d00,
        #ffe747 100%
    );
    background-size: 100% 200% ;
    &.index-1{
    background-position: 0% 100%;
 }
`;

const Container = styled.div`
display: flex;
flex-direction: column;
/* margin: 0 0 0 10px; */
/* padding: 0 50px; */
  transition: .3s all ease-out;
align-items: flex-start;
height: 100vh;
width: 100%;
/* overflow: hidden; */
position: relative;
flex-shrink: 0;
/* margin-left: 50px; */
/* padding-right: 25px; */
`;

const HeroContainer = styled.div`
display: grid;
grid-template-columns: 1fr max-content;
grid-template-rows: min-content 1fr;
/* padding: 0 30px; */
flex-shrink: 0;
height: 100vh;
/* scroll-snap-align: center;
scroll-snap-type: y mandatory;
scroll-behavior: smooth; */
align-items: center;
justify-content: center;
grid-template-areas:
    'nav nav'
    'text image'; 
padding: 0 1em;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas:
    'nav nav'
    'image image'
    'text text'; 
    padding: 0 5%;
}
`;

const Box = ({ children, bgdRef, bgColor, setVisibleIndex, index, currVisibleIndex, setTOutIndex, ...props }) => {
    // console.log("vertexShader", vertexShader)
    const wasInView = useRef(false)
    const [refItem, inView] = useIntersectionObserver({
        root: null,
        rootMargin: "-50% 0px -50% 0px",
        threshold: 0
    }, false);
    useEffect(() => {
        if (inView && bgdRef && bgdRef.current) {
            setVisibleIndex(index)
            wasInView.current = true
            // bgdRef.current.setRGB(...bgColor)
            bgdRef.current.style.background = bgColor
        } else {
            if (currVisibleIndex === index && wasInView.current) {
                wasInView.current = false
                setTOutIndex(index)
            }
        }
    }, [inView]);
    return (<Flexbox refID={refItem} flexDirection={"column"} justifyContent="center"
        // scrollSnapAlign="center" scrollSnapType="y mandatory" 
        height="100%"
        minHeight="100vh"
        mobileHeight="100%"
        gridTempCols="1fr 2fr" gridTempRows="1fr"
        mobilePadding="0 5%"
        scrollBehavior="smooth"
        gridTempRowsMobile="1fr 1fr"
        gridTempColsMobile="1fr"
        // maxWidth="600px"
        flexShrink="0" {...props} >
        {children}
    </Flexbox>)
}

const Section = () => {
    const bgdRef = useRef(null)
    const navigate = useNavigate()
    const [visibleIndex, setVisibleIndex] = useState(0)
    const [tOutIndex, setTOutIndex] = useState(-1)
    const data = [
        {
            h1: "You Can be a Critical Part of the Revolution",
            subtextStyling: {
                maxWidth: '1000px'
            },
            subtext: <>AI usually requires a LOT of data to see results.  Because real-world cost data only comes in small quantities, we use specialized techniques to create real upgrades in prediction power from just a limited amount of data.<br /><br />We're able to make an improvement every single time someone provides us with their actual project costs.<br /><br />That means each person who reports back with their real-world data makes a critical contribution.</>
        },
        {
            h1: "Why It's Important",
            subtextStyling: {
                maxWidth: '1000px'
            },
            subtext: <>Our project is all about helping people understand what a development project will truly cost, while our community reports real-world data that drives SeoCostCalc's development.
                <br /><br />
                This helps you save money on projects by not overpaying, or to avoid projects altogether that could turn into financial sinkholes.
            </>
        },
        {
            // h1: "WHY ITS IMPORTANT",
            subtextStyling: {
                maxWidth: '1000px'
            },
            subtext: <>
                <br /><br />SeoCostCalc is important because it helps you save money and get your project launched as cost-effectively as possible.
                <br /><br />We hope you'd like to join us in this mission.
                <br /><br />(Either way, we are happy for the opportunity to calculate your project cost!)</>
        },
        {
            h1: "How Do I Begin?",
            subtextStyling: {
                maxWidth: '1000px'
            },
            subtext: <>Simply fill out <span className="gradientText" style={{ fontWeight: '700', cursor: 'pointer' }} onClick={() => navigate("/form")}>this form</span > so we can calculate your estimate. <br></br>(It takes about 3 minutes.)
                < br /> <br />If you'd like to provide us your real-world cost data later on, please email us at {process.env.REACT_APP_SUPPORT_EMAIL} with your real-world costs and prediction ID number.
                <br /><br />We hope you enjoy your experience with our powerful AI development calculator.</>
        },
    ]
    return (
        <Container>

            <Flexbox column
                // scrollSnapType="y mandatory"
                scrollBehavior="smooth"
                overflow="auto scroll"
                // overflow="hidden auto"
                width="100%" height="100%"
                //  padding="0 30px 0 0"

                // mobilePadding="0 5%"
                // margin="var(--navBarHeight) 0 0" 
                className="bodyContainer">

                <HeroContainer flexDirection="row" display="grid">
                    <GridItem row="1" col="1 / span 2">
                        <Navigation shouldShow={true} /></GridItem>
                    <Flexbox column alignItems="start">
                        <H1 textAlign="start">Discover the cost to reach your SEO goals</H1>
                        <H2 maxWidth="1000px" textAlign="start">Describe your goals, enter your URL, and our AI calculates a realistic cost to achieve them.</H2>
                        <GlassButton
                            // className="hideOnDesktop"
                            text="Get Started" onClick={() => navigate("/form")} style={{ maxWidth: '400px' }} />
                    </Flexbox>

                    {/* <MiniForm /> */}
                    <CalcImage src={calc} />
                    {/* <Form className="hideOnMobile" /> */}
                </HeroContainer>

                <Box>
                    <Img src={crystalBall} />
                    <H1>What are SEO Goals?</H1>
                    <P >Typical goals include becoming a top result for popular search terms, increasing conversions without paying for ads, expanding web presence, or improving company reputation.</P>
                </Box>
                {/* <Box>
                    <img src={fullImage} style={{ width: '100%' }} />
                </Box> */}
                <SectionWithBgdImage>
                    <PlantImage src={fullImage} />
                    <div className='textcontainer'>
                        <P>{`SEO helps your business grow, but it's a large project so it's critical to calculate potential costs up front.`}</P></div>
                </SectionWithBgdImage>
                <SectionContainer column>
                    <img src={dumpster} style={{ objectFit: 'contain', flexShrink: '0', position: 'relative', width: '100%', maxWidth: '300px', height: 'auto' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <P >{`Knowing costs in advance avoids wasting money by starting a project without enough budget to finish.`}</P>
                        <P >{`It also helps prevent overpaying an SEO company, consultant, or even in-house employees.`}</P>
                    </div>
                </SectionContainer>
                <SectionContainer column>
                    <img src={calculator} width="50px" height="auto" />
                    <P >SeoCostCalc analyzes your company's current SEO status and web presence, then uses advanced AI to calculate the cost to reach your SEO goals. 
                        <br /><br /><span style={{ fontWeight: '500' }}>How advanced is the AI?</span><br /><br></br>It has actual “reasoning” ability and has read, and understood, a significant portion of the entire internet.  It's an expert on a wider variety of SEO methods and strategies than most human experts.
                        <br /><br />The AI is so advanced in its SEO knowledge, it can provide a realistic cost to achieve your SEO goals based on your current web presence.  <br /><br />It's here now, free to use.</P>
                </SectionContainer>
                <SectionContainer column>
                    <H1>How our AI Learns</H1>
                    <P maxWidth="1000px">We work to improve accuracy and keep cost estimates up to date by training our AI with data straight from our community.
                        <br /><br />When community members hit their SEO goals, they can tell us what the actual costs were, so we can use this data to make SeoCostCalc even better.<br /><br />AI is all about data, and we're the only platform we know of that's gathering fresh real-world cost data.<br /><br />
                        That's why we believe this is truly the ultimate SEO cost calculator.</P>
                </SectionContainer>

                <SectionContainer style={{ padding: '0 1rem' }}>
                    <img src={phone} style={{ objectFit: 'contain', flexShrink: '0', position: 'relative', width: '100%', maxWidth: '300px', height: 'auto' }} />
                    <div>
                        <H1 style={{ textAlign: 'left' }}>How it Works</H1>
                        <P maxWidth="1000px" style={{ textAlign: 'left' }}>It's quick and easy.<br /><br />Click the "Get Started" button, briefly describe your SEO goals, and enter your URL so we can analyze your current web presence.<br /><br />That's it!<br /><br /><span style={{ fontWeight: '600' }}>Your information is anonymized for privacy</span>, then fed into the AI.<br /><br />After you receive the estimate and go on to achieve your goals, please consider returning to share real-world cost data.<br /><br />If not, we're still just as happy to calculate your SEO costs!</P>
                    </div>
                </SectionContainer>
               
                <SectionContainer column>
                    <img src={inspectCost} width="90%" height="auto" style={{ maxWidth: '100px' }} />
                    <H1>Why It's Important</H1>
                    <div className='textcontainer' >
                        <P maxWidth="1000px">SEO can be the foundation of a profitable business.<br /><br />(It's hard to beat getting conversions without paying for ads.)
                            <br /><br />
                            It's important for every business to use SEO, but critical to choose the right strategy for your unique situation.
                            <br /><br />SeoCostCalc helps you save money and choose the right strategy to reach your goals.
                          
                        </P>
                    </div>
                </SectionContainer>
                <SectionContainer column>
                    <H1>How Do I Begin?</H1>
                    <div className='textcontainer' >
                        <P maxWidth="1000px">Simply fill out <span className="" style={{ fontWeight: '700', cursor: 'pointer' }} onClick={() => navigate("/form")}>this form</span > so we can calculate your estimate. <br></br>(It takes less than 1 minute.)
                           
                        
                        </P>
                    </div>
                </SectionContainer>
                {/* {
                    data.map((it, index) => {
                        const { img, h1, subtext, bg, subtextStyling, imgProps } = it
                        const styles = subtextStyling ? subtextStyling : {}
                        const imgStyles = imgProps ? imgProps : {}
                        return (<Box
                            key={index}
                            index={index}
                            bgdRef={bgdRef}
                            currVisibleIndex={visibleIndex}
                            setVisibleIndex={setVisibleIndex}
                            setTOutIndex={setTOutIndex}
                            bgColor={bg || `hsl(${(270 - 200) / data.length * (data.length - index) + 200} , 100%, 40%)`}
                        >
                            {img && <Img src={img} {...imgStyles} />}
                            {h1 && <H1>{h1}</H1>}
                            {subtext && <P {...styles}>{subtext}</P>}
                        </Box>)
                    })
                } */}
                <Footer shouldShow={true} />

            </Flexbox>
            {/* <ScrollButton /> */}
        </Container >
    )
}


export default Section