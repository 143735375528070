import React from 'react';
import * as Select from '@radix-ui/react-select';
import classnames from 'classnames';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';
import CheckmarkValidate from './CheckmarkValidate';
import Flexbox from './Flexbox';
// import './styles.css';

export const Trigger = styled(Select.Trigger)`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1;
    height: 49px;
    gap: 5px;
    border: none;
    background-color: var(--underLineThemeBgd);
    color: var(--dropdownColor);
    box-shadow: 0 0 4px rgba(var(--formBlue2Raw),0.95);
    margin: ${({ margin }) => margin};
    min-width: 250px;
    &:hover {
        background-color: var(--mauve3);
    }
    &:focus {
        box-shadow: 0 0 0 2px black;
    }
    &[data-placeholder] {
        color: var(--violet9);
    }
`;

const RadixDropdown = ({ hideHeader = false, margin, placeholder = "Select...", options, onValueChange = () => { }, value,
    showValidationCheckmark = false, ...props }) => {
    return (

        <Flexbox alignItems="center">
            <Select.Root
                value={value}
                onValueChange={(v) => {
                    let selected =
                        Array.isArray(options) ? options :
                            Object.keys(options).map(option => {
                                return (options[option]?.options)
                                // return (options[option]?.options.find(item => item.value === v))
                            })
                    selected = selected.flat().find(item => item.value === v)
                    // let options
                    if (selected)
                        onValueChange(selected)
                }}
                {...props}
            >
                {
                    !hideHeader && <Trigger
                        margin={margin}
                        // className="SelectTrigger" 
                        aria-label="Food">
                        <Select.Value aria-label={value}>
                            {value}
                        </Select.Value>
                        <Select.Icon className="SelectIcon">
                            <ChevronDownIcon />
                        </Select.Icon>
                    </Trigger>}
                <Select.Portal>
                    <Select.Content className="SelectContent">
                        <Select.ScrollUpButton className="SelectScrollButton">
                            <ChevronUpIcon />
                        </Select.ScrollUpButton>
                        <Select.Viewport className="SelectViewport">

                            {
                                Array.isArray(options) && <Select.Group>{
                                    options.map(option => {
                                        const { innerText, id } = option
                                        return (

                                            <SelectItem value={id} key={id}>{innerText}</SelectItem>

                                        )
                                    })
                                }</Select.Group>
                            }
                            {
                                typeof (options) === 'object' &&
                                Object.keys(options).map((optionKey, index) => {
                                    let { groupLabel, options: _catOptions } = options[optionKey]
                                    if (!_catOptions)
                                        return <React.Fragment key={index}></React.Fragment>
                                    return (<React.Fragment key={index}>
                                        <Select.Group key={groupLabel}>
                                            {groupLabel && <Select.Label className="SelectLabel" key={`${groupLabel}-SelectLabel`} >{groupLabel}</Select.Label>}
                                            {
                                                _catOptions.map(it => {
                                                    let item = typeof (it) === 'string' ? it : it['innerText']
                                                    return (<SelectItem value={item} key={item}>{item}</SelectItem>)
                                                })
                                            }
                                        </Select.Group>
                                        <Select.Separator className="SelectSeparator" key={`${groupLabel}-separator`} />
                                    </React.Fragment>)
                                })
                            }
                        </Select.Viewport>
                        <Select.ScrollDownButton className="SelectScrollButton">
                            <ChevronDownIcon />
                        </Select.ScrollDownButton>
                    </Select.Content>
                </Select.Portal>

            </Select.Root>
            {showValidationCheckmark && <CheckmarkValidate {...props}
                margin="0 0 0 5px"
                position="relative" right={"unset"} top={"unset"} />}
        </Flexbox>
    )
};

const SelectItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
    return (
        <Select.Item className={classnames('SelectItem', className)} {...props} ref={forwardedRef}>
            <Select.ItemText>{children}</Select.ItemText>
            <Select.ItemIndicator className="SelectItemIndicator">
                <CheckIcon />
            </Select.ItemIndicator>
        </Select.Item>
    );
});

export default RadixDropdown;
