import React from 'react'
import styled from 'styled-components'
import Flexbox from '../Flexbox'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Button from '../GlassButton'
import logo from '../../images/devCostCalculatorFull.png'
const Link = styled(NavLink)`
/* color: #fff; */
font-size: 14px;
color: var(--primary);
width: min-content;
white-space: nowrap;
grid-area: ${({ gridArea }) => gridArea};
grid-column: ${({ col }) => col};
grid-row: ${({ row }) => row};
:hover{
    text-decoration: underline;
}
`;

const OnPageLink = styled.a`
color: var(--primary);
width: min-content;
white-space: nowrap;
grid-area: ${({ gridArea }) => gridArea};
grid-column: ${({ col }) => col};
grid-row: ${({ row }) => row};
:hover{
    text-decoration: underline;
}
`;

const MailLink = styled.a`
color: var(--primary);
width: min-content;
:hover{
    text-decoration: underline;
}
`;

const List = styled.ul`
    margin: 0;
    margin-left: 50px;
    padding: 0;
    li{
        margin-top: 20px;
    }
    `

const ListItem = styled.li`
    /* height: 40px; */
    list-style: none;
    
    `

const Footer = ({ shouldShow = false }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const linksStyling = {
        row: '2'
    }
    if (pathname.includes('form'))
        return (<></>)
    if ((pathname === '/' || pathname.includes('/?')) && !shouldShow)
        return (<></>)
    return (
        <Flexbox column
            color="var(--footerColor)"
            style={{ background: 'var(--footerBgd)' }}
            background="var(--formBackground)"
            width="100%"
            // scrollSnapAlign="start" scrollSnapType="y mandatory"
            boxShadow="rgba(0, 0, 0, 0.5) 0px 0 5px" zIndex="3" position="relative">

            <GridContainer

                mobileBreakPoint="1080px"
                position="relative"
                justify="center"
                align="center"
                gridTemp={{ cols: '1fr', rows: 'min-content min-content' }}
                gridTempMobile={{ cols: '1fr', rows: 'min-content min-content min-content' }}
                column maxWidth="100vw" width="100%" overflow="hidden" padding="75px 30px 0" gap="30px"
            // color="#fff"
            >
                {/* <img src={logo} height="70%" width="auto" style={{
                    // borderRadius: '50%',
                    opacity: '0.05',
                    position: 'absolute',
                    alignSelf: 'start',
                    left: '50px',
                    top: '50%',
                    transform: "translateY(-50%)",
                    maxWidth: 'calc(100vw - 100px)',
                    // maxHeight: 'auto',
                    objectFit: 'contain'
                }} /> */}
                <Flexbox maxWidth="1200px" mobileBreakPoint="1080px" className="wrapMobile" flexGrow="2" position="relative" justifyContent="start" alignItems="center" gap="25px 0">
                    <GridContainer alignSelf="center" justifySelf="center" gap="10px" column
                        alignItems="center" flexBasis="50%"
                        justifyItemsMobile="center"
                        mobileBreakPoint="1080px"
                        gridTemp={{ rows: 'repeat(3, min-content)' }}
                        flexGrowMobile="1" alignText="start" mobileMargin="0" paddingMobile="0"
                        padding="0 50px 0 0" className="noPaddingMobile">
                        <h3>SeoCostCalc</h3>
                        {/* <img src={logo} style={{ width: '100%', height: 'auto', maxWidth: '200px' }} alt="Seo Cost Calc" /> */}
                        <h4 style={{ fontSize: '1.1rem', textAlign: 'left' }} className="centerOnMobile">AI-powered SEO cost calculator</h4>
                        <Button
                            width="100%"
                            minWidth="200px"
                            id="footerConsultationButton"
                            background="var(--primaryBackground)"
                            border="1px solid var(--primary)"
                            mobileMax="100%"
                            text="Get Started"
                            onClick={() => navigate('/form', { state: { form_entry: 'Footer - Button' } })}
                        />
                    </GridContainer>
                    <Flexbox border="none;border-left: 1px solid var(--footerColor)"
                        // padding="0 0 0 25px"

                        height="100%" row alignItems="start" alignSelf="center" justifyContent="start" alignText="start" className="colOnMobile">
                        <Flexbox

                            position="relative"
                            column

                            justifyContent="start"
                            // padding="0 0 0 50px"
                            alignItems="start"
                            // gap="20px"
                            alignText="left"
                        >
                            <List>
                                <h4>Resources</h4>
                                <ListItem><Link to="/privacy-policy">Privacy Policy</Link></ListItem>
                                <ListItem><Link to="/do-not-sell-my-info">Do Not Sell My Info</Link></ListItem>
                                <ListItem><Link to="/terms">Terms & Conditions</Link></ListItem>
                                <ListItem>{
                                    pathname.includes('privacy-policy') &&
                                    <OnPageLink href="#cookies-and-tracking">Cookie Policy</OnPageLink>
                                }
                                    {
                                        !pathname.includes('privacy-policy') &&
                                        <Link to="/privacy-policy#cookies-and-tracking">Cookie Policy</Link>
                                    }
                                </ListItem>
                            </List>
                        </Flexbox>
                        <Flexbox
                            position="relative"
                            column
                            justifyContent="start"
                            alignItems="start"
                            mobileMargin="25px 0 0"
                            alignText="left"
                        >
                            <List>
                            <h4>Menu</h4>
                            <ListItem><Link to="/">Home</Link></ListItem>
                            {/* <ListItem><Link to="/results">Results</Link></ListItem> */}
                            {/* <ListItem>
                                    {
                                        pathname !== '/' &&
                                        <>
                                            <Link to="/#how-it-works">How It Works</Link>

                                        </>
                                    }
                                    {
                                        pathname === '/' &&
                                        <>
                                            <OnPageLink href="#how-it-works">How It Works</OnPageLink>
                                        </>
                                    }
                                </ListItem> */}
                            {/* <ListItem><Link to="/contact" state={{ form_entry: 'Footer' }}>Contact Us</Link></ListItem> */}
                            {/* <ListItem><Link to="/about">About</Link></ListItem> */}
                            </List>
                        </Flexbox>
                        <Flexbox
                            position="relative"
                            column
                            justifyContent="start"
                            alignItems="start"
                            mobileMargin="25px 0 0"
                            alignText="left"
                        >
                            <List>
                                <h4 >Get In Touch</h4>
                                <ListItem><MailLink
                                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} >{process.env.REACT_APP_SUPPORT_EMAIL}</MailLink></ListItem>
                                {/* <ListItem>
                                    <label for="" style={{fontSize: '12px'}}>Ask A Legal Question</label>
                                    <Flexbox position="relative" align="center">

                                        <InputField wrapperWidth="100%" useUnderlineTheme={true} placeholder="How much time do I have to file a claim?" padding="0 40px 0 20px"/>
                                        <Flexbox position="absolute" right="10px"><NavArrow skipText={true} /></Flexbox>
                                    </Flexbox></ListItem> */}
                            </List>
                        </Flexbox>
                    </Flexbox>

                </Flexbox>

                <GridItem
                    // color="var(--primary)" 
                    // margin="5px 0"
                    mobileRow="3" fontSize="12px" padding="10px 50px" position="relative" alignText="center" className="cp">
                </GridItem>
            </GridContainer>
            {/* <FooterDisclaimer /> */}
        </Flexbox>
    )
}

export default Footer