import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import TiledInput from './TiledInput'
import InputField from './InputField'
import TextArea from './TextArea'
import Flexbox from './Flexbox'
import { toTitleCase, formatPhoneNumber } from '../utils'
import states from "../utils/states.js";
import { validateEmail } from './Form'
import PageContentContainer from './PageContentContainer'
import GeographicAreaDetailed from './GeographicAreaDetailed'
import Button from './ButtonHome'
import Dropdown from './Dropdown'
import RadioButtons from './RadioButtons'
import Checkbox from './Checkbox'
import AsSeenOn from './AsSeenOn'
import CheckmarkValidate from './CheckmarkValidate'
import FAQ from './FAQ'
import Cases from './Cases'
import API from '../API'
import GridContainer from './GridContainer'
import Section from './Section'
import ScrollButton from './ScrollButton'
const Container = styled.div`
/* box-shadow: var(--formTileBoxShadow); */
display: grid;
position: relative;
width: 95%;
max-width: 700px;
padding: 20px 20px 40px;
grid-auto-flow: row;
grid-template-columns: 1fr;
grid-auto-rows: min-content;
gap: 15px;
height: 100%;
min-height: calc(100vh - var(--navBarHeight));
`;

const Label = styled.label`
font-size: 14px;
text-align: left;

h3{
    margin: 0 0 5px;
}
`;

const Link = styled(NavLink)`
color: var(--formBlue1);
display: inline;
`;

const GridC = styled.div`
display: grid;
grid-template-rows: min-content;
grid-template-columns: ${({ cols = 2, gridCol }) => gridCol || `repeat(${cols}, 1fr)`};
gap: 15px;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    grid-template-columns: 1fr;
    /* gap: 0; */
    grid-template-rows: min-content;
}
`;

const Box = ({ showContainer = false, cols, showLabel = false, gridCol, forEl, label, children }) => {
    if (!showContainer)
        return (
            <>{children}</>
        )
    else
        return (
            <div>
                {showLabel && <Label style={{ fontSize: '14px', margin: '0' }} for={forEl}>
                    <h3 >{label}</h3>
                </Label>}
                <GridC cols={cols} gridCol={gridCol}>{children}</GridC>
            </div>
        )
}

const Home2 = () => {
    return (
        <PageContentContainer 
        minHeight="100vh"
        // margin="calc(var(--navBarHeight) * -1) 0 0"
        height="100vh"
        padding="0"
        color="#fff"
        scrollBehavior="smooth"
        // scrollSnapType="y mandatory"
        background="radial-gradient(circle at 25% 25%, #094d89, #050027 50% )"
        // background="linear-gradient(135deg,#000, #140033 90%, #320052 )"
        // background="#000"
        transition="0.2s all  linear"
        overflow="auto"
        column
        >
            <Section />
            {/* <Flexbox
                width="100%"
                margin="0 0 20px">
                <AsSeenOn _offset="0px" position="relative"
                    filter="brightness(0) saturate(100%) invert(34%) sepia(46%) saturate(5152%) hue-rotate(219deg) brightness(102%) contrast(101%)" />
            </Flexbox> */}
            {/* <Cases /> */}
            {/* <FAQ isHome={true} /> */}
        </PageContentContainer>
    )
}

export default Home2