const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
    let elements;
    elements = document.querySelectorAll(className);
    Array.from(elements).forEach((element) => {
        let BBox = element.getBBox();
        let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
        let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
        let BBoxX = BBox["x"] + BBoxX2;
        let BBoxY = BBox["y"] + BBoxY2;
        BBox = BBoxX + "px" + " " + BBoxY + "px";
        element.style.transformOrigin = BBox;
    });
}

const validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Regular expression for domain validation


    if (!domain || !domain.match(domainRegex)) {
        return false
    } else
        return true

    // If the domain is valid, proceed to the next middleware or route handler
    // next();
};

const toTitleCase = (str, skipLowerCase = false) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + (skipLowerCase ? txt.substr(1) : txt.substr(1).toLowerCase());
        }
    );
}
const formatPhoneNumber = (phoneNumber) => {
    let number = phoneNumber.slice(0, 10).split('').reduce((a, b, index) => {
        if ((index) % 3 === 0 && index < 7 && index > 0)
            a = a + "-" + b
        else
            a = a + b
        return a
    }, '')

    return number
}

const debounce = (callback, wait) => {
    let timeout;
    return function (...args) {
        clearTimeout(timeout)
        timeout = setTimeout(async () => {
            await callback(...args)
        }, wait)
    }

}

function gtag_report_conversion(url = undefined, send_to) {
    var callback = function () {
        if (typeof (url) !== undefined)
            window.location = url

    }
    let conversionOb = { 'send_to': send_to }
    if (typeof (url) !== undefined)
        conversionOb['event_callback'] = callback
    if (typeof (window.gtag) === 'function')
        window.gtag('event', 'conversion', {
            ...conversionOb
        })
    return false

}

class IconSelectionOption {
    /**
     * 
     * @param {string} displayName 
     * @param {*} img 
     * @param {*} id 
     * @param {*} text 
     * @param {*} imgProps 
     * @param {*} subcategories 
     */
    constructor(displayName, img = null, id = null, text = null, imgProps, subcategories) {
        this.id = id || displayName.replace(/ /g, "-").toLowerCase()
        this.img = img
        this.text = text || displayName
        this.displayName = displayName
        this.subcategories = subcategories
        this.imgProps = imgProps
        // this.formattedValue = formattedValue || displayName
    }
}

class IconSelectionOptionOb {
    /**
     * 
     * @param {string} displayName 
     * @param {*} img 
     * @param {*} id 
     * @param {*} text 
     * @param {*} imgProps 
     * @param {*} subcategories 
     */
    constructor({ displayName, img = null, id = null, text = null, imgProps,
        subcategories, formattedValue, subtext = null, showInputFieldOnClick = false,
        inputFieldValue = null, placeholder = null }) {
        this.id = id || displayName.replace(/ /g, "-").toLowerCase()
        this.img = img
        this.text = text || displayName
        this.displayName = displayName
        this.subcategories = subcategories
        this.imgProps = imgProps
        this.formattedValue = formattedValue || displayName
        this.subtext = subtext
        this.showInputFieldOnClick = showInputFieldOnClick
        if (showInputFieldOnClick && inputFieldValue !== null) {
            this.inputFieldValue = inputFieldValue
        }
        if (placeholder && showInputFieldOnClick)
            this.placeholder = placeholder
    }
}

class RadioButtonEl {
    constructor({ id, name, label, options, getPrevValue = () => { }, onChange, condition, initialIndex = 0, validate, noDefault = false, showValidAtLabelLevel }) {
        this.id = id
        this.label = label
        this.name = name
        this.type = 'radio'
        this.options = options.map(it => ({ id: it, value: it, innerText: it, label: it }))
        let _initialIndex = initialIndex < options.length ? initialIndex : 0
        let initialValue = getPrevValue(this.name, noDefault ? "" : this.options[_initialIndex].label)
        this.getPrevValue = getPrevValue
        this.selected = Array.isArray(initialValue) ? initialValue[_initialIndex] : initialValue
        this.value = Array.isArray(initialValue) ? initialValue[_initialIndex] : initialValue
        if (onChange)
            this.onChange = onChange
        if (condition)
            this.condition = condition
        if (validate)
            this.validate = validate
        if (showValidAtLabelLevel)
            this.showValidAtLabelLevel = showValidAtLabelLevel
    }
    // get selected() {
    //     let initialValue = this.getPrevValue(this.name, [this.options[0].label])
    //     return (Array.isArray(initialValue) ? initialValue[0] : initialValue)
    // }
    // get value() {
    //     let initialValue = this.getPrevValue(this.name, [this.options[0].label])
    //     return initialValue
    // }
}


const newEnum = (arrayOfIconOptions) => {
    return Object.fromEntries(arrayOfIconOptions.map((it, idx) => {
        let _it
        if (typeof (it) === 'string') {
            _it = new IconSelectionOption(it)

        }
        else if (typeof (it) === 'object')
            _it = new IconSelectionOptionOb(it)
        return [_it.id, _it]
    }))
}

function camelToTitleCase(str) {
    // Use a regular expression to match any instance of an uppercase letter
    // followed by one or more lowercase letters or digits
    const result = str.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

export { camelToTitleCase, fixSvgElementCoordinates, toTitleCase, formatPhoneNumber, debounce, gtag_report_conversion, IconSelectionOptionOb, IconSelectionOption, newEnum, RadioButtonEl, validateDomain }