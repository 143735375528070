import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PageContentContainer from './PageContentContainer'
import InputField from './InputField'
import { validateEmail } from './Form'
import { Container } from './FormNew/Step'
import GlassButton from './GlassButton'
import CheckmarkValidate from './CheckmarkValidate'
import API from '../API'
import TextArea from './TextArea'
import Airplane from './Airplane'

const Dot = styled.div`
width: 5px;
position: relative;
display: inline-block;
flex-shrink: 0;
flex-grow: 0;
background: #fff;
height: 5px;
border-radius: 100%;
-webkit-animation: loader 1.4s infinite ease-in-out both;
animation: loader 1.4s infinite ease-in-out both;
&.bounce1{
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
&.bounce2{
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
&.bounce3{

}
@-webkit-keyframes loader {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}
@keyframes loader {
    0%, 80%, 100% {  
    -webkit-transform: scale(0);
    transform: scale(0);
    } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    }
}
`;

const Msg = styled.div`
    font-size: 12px;
    height: 12px;
`;

const LoadingDots = () => {
    return (
        <div style={{ width: 'min-content', margin: '0 auto', display: 'grid', gap: '1px', gridTemplateColumns: 'repeat(3,min-content)', gridTemplateRows: 'min-content' }}>
            <Dot className="bounce1"></Dot>
            <Dot className="bounce2"></Dot>
            <Dot className="bounce3"></Dot>
        </div>
    )
}

const Feedback = () => {
    const [user, setUser] = useState({
        email: '',
        settlement: '',
        additionalFeedback: '',
        loading: false,
        userFound: false,
        showCheckmark: false,
        msg: '',
        didSend: false
    })

    const { didSend, showCheckmark, msg, userFound, email, settlement, additionalFeedback, loading } = user
    const findUser = async () => {

        if (!validateEmail(email)) {
            setUser(prev => ({ ...prev, msg: 'Invalid email' }))
            return
        }
        setUser(prev => ({ ...prev, msg: '', loading: true }))
        const { msg, status } = await API.findSetCalcUser(email)
        setUser(prev => ({ ...prev, msg: '', loading: false, userFound: status === 201 ? true : false, msg }))
    }
    const submitFeedback = async () => {
        if (settlement === "") {
            setUser(prev => ({ ...prev, showCheckmark: true }))
            return
        }
        const res = await API.settlementFeedback({ email, followUp: { actualSettlement: settlement, additionalFeedback } })
        if (res)
            setUser(prev => ({ ...prev, didSend: true }))
    }
    return (
        <PageContentContainer
            minHeight="100vh"
            height="100vh"
            padding="0"
            color="#fff"
            scrollBehavior="smooth"
            background="linear-gradient(135deg,#000, #140033 90%, #320052 )"
            transition="0.2s all  linear"
            overflow="auto"
            column>
            {
                didSend && <>
                    <Airplane />
                    <h2>Thanks for your feedback!</h2></>
            }
            {!didSend && <Container>
                <h2>Welcome back</h2>
                <p>Thanks for helping us tune our settlement calculator.</p>
                <h5 style={{ margin: "0 0 10px", alignSelf: "start", fontSize: '1em' }}>
                    First let's locate your original submission with your email.
                </h5>
                <InputField
                    useUnderlineTheme={true}
                    placeholder="Email"
                    padding={"0 40px 0 20px"}
                    childComponent={
                        <GlassButton
                            className="dark"
                            text={<span style={{ display: 'block', opacity: userFound ? '1' : '0.5', margin: '0 auto', width: '100%' }}>
                                {loading ? <LoadingDots /> : userFound ?
                                    <CheckmarkValidate isValid={true} hideContainerStroke={"none"} /> : '➜'}
                            </span>} width="30px" borderRadius="30px" height="30px"
                            right="10px"
                            position="absolute"
                            background="rgba(0,0,0,0)"
                            onClick={findUser}
                            colorHover="#fff"
                            borderHover="1px solid #fff"
                            border="1px solid #fff" bgdHover="rgba(255,255,255,0.1)" />}
                    onChange={(e) => {
                        setUser(prev => ({
                            ...prev,
                            userFound: false,
                            email: e.target.value,
                            msg: ''
                        }))
                    }} />
                <Msg>{msg}</Msg>
                {
                    userFound &&
                    <>
                        <h5 style={{ margin: "50px 0 10px", alignSelf: "start", fontSize: '1em' }}>
                            What was your final, accepted settlement?
                        </h5>
                        <InputField
                            useUnderlineTheme={true}
                            showValidationCheckmark={showCheckmark}
                            isValid={settlement !== ""}
                            type="price"
                            value={settlement}
                            onChange={(formattedPrice) => {
                                setUser(prev => ({
                                    ...prev,
                                    showCheckmark: false,
                                    settlement: formattedPrice,
                                }))
                            }} />
                        <h5 style={{ margin: "0 0 10px", alignSelf: "start", fontSize: '1em' }}>
                            Do you have any other feedback?
                        </h5>
                        <TextArea
                            useUnderlineTheme={true} maxLength={1000} showCharCount={true} value={additionalFeedback}
                            // maxWidth="400px"
                            onChange={(e) => {
                                setUser(prev => ({
                                    ...prev,
                                    additionalFeedback: e.target.value
                                }))
                            }} />
                        <GlassButton text="Submit" onClick={submitFeedback} />
                    </>
                }
            </Container>}
        </PageContentContainer>
    )
}

export default Feedback