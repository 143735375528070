import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isPossiblePhoneNumber } from 'react-phone-number-input'
// phone number optional, email before number
import Step from "./Step";
import NavArrow from "./NavArrow";
import Flexbox from "../Flexbox";
import ProgressBar from "./ProgressBar";
import PageContentContainer from "../PageContentContainer";
import Button from "../Button";
import FormGrid from "./FormGrid";
import FormContentContainer from "./FormContentContainer";
import anime from 'animejs'
import API from "../../API";
import { useUnload } from '../../hooks/useUnload'
import {
  formatPhoneNumber,
  IconSelectionOption,
  newEnum,
  toTitleCase,
  validateDomain,
} from "../../utils";
import states from "../../utils/states.js";
import GridItem from "../GridItem";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

function validateName(str) {
  return /\d/.test(str);
}

const Form = ({ }) => {
  const formAbandonment = useRef('')
  const formEntry = useRef('Embedded Header')
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.form_entry) {
      formEntry.current = location.state.form_entry
    }
  }, [])
  const navigate = useNavigate();
  const [serverResponse, setServerResponse] = useState({
    message: "",
    shouldShow: false,
  });
  let previousFormData = window.sessionStorage.getItem("formData");
  const [person, setPerson] = useState("you");

  const injuryIsLossOfLife = (val) => val.toLowerCase() === "loss of life"
  if (previousFormData) {
    previousFormData = JSON.parse(previousFormData);
  }
  const getPrevValue = (name, defaultVal = "") => {
    // if (!previousFormData || typeof (previousFormData) !== 'object' || previousFormData[name] )
    //   return defaultVal
    return ((previousFormData && (previousFormData[name] !== undefined)
      ? previousFormData[name]
      : defaultVal))
  };
  const getPrevEnum = (dependency, enumValues) => { };
  const getStep = (fieldName, _steps = steps) => {
    const stepId = Object.keys(_steps).find((id) => {
      return _steps[id].name === fieldName;
    });
    return stepId;
  };

  const jumpStepOnLoad = (_steps, stepOrder = []) => {
    if (location.state && location.state.jumpToStepName) {
      const {
        state: { jumpToStepName, svgPath, svgPathId, form_entry },
      } = location;
      let geographicAreaDetailedStep = getStep(jumpToStepName, _steps);
      sessionStorage.setItem("svgPath", JSON.stringify({ svgPath, svgPathId }));
      window.history.replaceState({}, document.title);
      if (form_entry) {
        formEntry.current = form_entry
      }
      // if (stateChange)
      if (geographicAreaDetailedStep) return geographicAreaDetailedStep;
      else return getPrevValue("active", stepOrder[0]);
    } else {
      return getPrevValue("active", stepOrder[0]);
    }
  };
  const _data = {
    // person: 'you',
    steps: {
      "step-9": {
        id: "step-9",
        name: "moreInfo",
        type: "text",
        stepWidth: "100%",
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        value: "",
        // value: getPrevValue('moreInfo', "email"),
        heading: "Tell us what happened",
        subtext: (
          <>
            The next few questions are{" "}
            <strong style={{ textDecoration: "underline" }}>optional</strong>,
            <br />
            but may be helpful to know before your call.
            <br /> <br /> Feel free to skip them if you prefer.
          </>
        ),
        labelInternal: true,
      },
      "step-4": {
        id: "step-4",
        type: "final-step",
        name: "finalStep",
        fieldOrder: ["field-4", "field-5", "field-1", "field-3", "field-2"],
        validate: function () {
          let isValid = true;
          let keys = this.fieldOrder;
          for (let index = 0; index < keys.length; index++) {
            const element = this.fields[keys[index]];
            if (typeof element["validate"] === "function") {
              let v = element.validate();
              if (!v["value"]) {
                isValid = false;
                // setServerResponse({ message: v['message'] ? v['message'] : '', shouldShow: false })
                break;
              }
            }
          }
          // if (isValid)
          // setServerResponse({ message: '', shouldShow: false })
          return isValid;
        },
        fields: {
          "field-1": {
            id: "field-1",
            name: "phoneNumber",
            placeholder: { phoneNumber: "Phone [i.e. 213-555-5555]", extension: "25" },
            label: "Phone",
            stepWidth: "100%",
            labelInternal: true,
            type: "tel",
            country: getPrevValue('country', 'US'),
            get isValid() { return (this.validate())['value'] },
            shouldShow: false,
            value: getPrevValue("phoneNumber", {
              phoneNumber: "",
              extension: "",
            }),
            validate: function (v = this.value["phoneNumber"]) {
              return ({ value: isPossiblePhoneNumber(v), message: "PhoneNumber  invalid" })
              let isValid = true;
              let val = v.trim();
              if (
                (val.length > 0 &&
                  !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(val)) || val.trim().length === 0
              )
                isValid = false;
              return { value: isValid, message: "Phone Number invalid" };
            },
            onKeyDown: function (event) {
            },
            onCountryChange: function (v) {
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      ['field-1']: {
                        ...prev.steps["step-4"].fields['field-1'],
                        country: v
                      },
                    },
                  },
                },
              }));
            },
            onChange: function (event, type = "extension") {
              if (typeof (event) === 'object') {
                const _val = event.target.value;
                setServerResponse({ message: "", shouldShow: false });
                setState((prev) => ({
                  ...prev,
                  steps: {
                    ...prev.steps,
                    ["step-4"]: {
                      ...prev.steps["step-4"],
                      fields: {
                        ...prev.steps["step-4"].fields,
                        [this.id]: {
                          ...prev.steps["step-4"].fields[this.id],
                          value: { phoneNumber: _val, extension: '' },
                          isValid: this.validate(_val)
                        },
                      },
                    },
                  },
                }));
              } else if (typeof (event) === 'string') {
                setServerResponse({ message: "", shouldShow: false });
                setState((prev) => ({
                  ...prev,
                  steps: {
                    ...prev.steps,
                    ["step-4"]: {
                      ...prev.steps["step-4"],
                      fields: {
                        ...prev.steps["step-4"].fields,
                        [this.id]: {
                          ...prev.steps["step-4"].fields[this.id],
                          value: { phoneNumber: event, extension: '' },
                          isValid: this.validate(event)
                        },
                      },
                    },
                  },
                }));
              }
            },
          },
          "field-2": {
            id: "field-2",
            name: "additionalDetails",
            type: "textArea",
            placeholder: "Additional Details / Comments",
            label: "Additional Details",
            // subtextLabel:
            //   "Optional, but the more specific you are about your case details, the better we can connect you.",
            value: getPrevValue("additionalDetails"),
            isValid: true,
            shouldShow: false,
            maxlength: 500,
            rows: 5,
            onChange: function (event) {
              const _val = event.target.value;
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: _val,
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-3": {
            id: "field-3",
            name: "email",
            placeholder: "Email",
            label: "Email",
            value: getPrevValue("email"),
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
              return { value: validateEmail(v), message: "Invalid email" };
            },
            onChange: function (event) {
              const _val = event.target.value;
              let { value: isValid } = this.validate(_val);
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: _val,
                        isValid,
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-4": {
            id: "field-4",
            name: "name",
            placeholder: "Name",
            label: "Name",
            value: getPrevValue("name"),
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
              return {
                value: v.trim() !== "" && !/\d/.test(v),
                message: "Name required",
              };
            },
            onChange: function (event) {
              const _val = event.target.value;
              let { value: isValid } = this.validate(_val);
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: _val,
                        isValid,
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-5": {
            id: "field-5",
            name: "company",
            placeholder: "Company",
            label: "Company",
            value: getPrevValue("company"),
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
              return {
                value: v.trim() !== "" && !/\d/.test(v),
                message: "Company required",
              };
            },
            onChange: function (event) {
              const _val = event.target.value;
              let { value: isValid } = this.validate(_val);
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: _val,
                        isValid,
                      },
                    },
                  },
                },
              }));
            },
          },
        },
      },
      "step-15": {
        id: "step-15",
        name: "serviceRegions",
        type: "iconSelection",
        defaultValue: 'N/A',
        value: getPrevValue("serviceRegions", []),
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        storeFullOption: true,
        isOptional: false,
        textPosition: 'top',
        label: "What geographic regions do you need SEO services for?",
        get enum() { return (newEnum(['Local', 'National', 'International'])) },
        valueArrayKey: 'displayName',
        labelInternal: true,
        valueIsSelectedKey: 'displayName',
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          subtextFontSize: "calc(1/0.90 * 12px)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      "step-16": {
        id: "step-16",
        name: "industry",
        type: "iconSelection",
        defaultValue: 'N/A',
        value: getPrevValue("industry", []),
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        storeFullOption: true,
        isOptional: false,
        textPosition: 'top',
        label: "Which industry?",
        get enum() {
          return (newEnum([
            // {displayName: "Home Improvement/Maintenance", subtext: "Gutters, windows, flooring, roofing, etc."},
            'Home Improvement/Maintenance',
            'Travel/Hospitality',
            'Beauty/Wellness',
            'Legal',
            'Restaurant',
            'Education',
            'Retail/Ecommerce',
            'Finance',
            'Auto',
            'Food and Beverage',
            'Tech',
            'Energy',
            'Real Estate',
            'Health/Medical',
            'Sports/Entertainment',
            'B2B',
            {
              displayName: "Other",
              id: "other-16",
              showInputFieldOnClick: true,
              placeholder: "Specify Industry",
              get inputFieldValue() {
                let _prev = getPrevValue("industry", []);
                if (Array.isArray(_prev)) {
                  let vArrayIncOther = _prev.find(s => s.id === this.id)
                  if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                    return vArrayIncOther["inputFieldValue"];
                  return '';
                } else if (typeof (_prev) === 'object' && _prev["inputFieldValue"] !== undefined) {
                  return _prev["inputFieldValue"];
                }
                return ''
              },
            },]))
        },
        valueArrayKey: 'displayName',
        labelInternal: true,
        valueIsSelectedKey: 'displayName',
        validate: function (_val = this.value) {
          console.log("validating....", this.value)
          if (this.value.length === 0 || this.value === 'N/A')
            return false
          if (Array.isArray(this.value)) {
            let arrIncludesOther = this.value.find(it => it.inputFieldValue !== undefined)
            if (arrIncludesOther && arrIncludesOther.inputFieldValue.trim().length === 0)
              return false
            if (typeof (this.value) === "object") {
              if (this.value.showInputFieldOnClick === undefined || !this.value.showInputFieldOnClick)
                return true
              else if (this.value.showInputFieldOnClick === true && this.value.inputFieldValue.trim().length === 0)
                return false
              else
                return true
            };
          } else if (typeof this.value === 'object') {
            if (this.value.inputFieldValue !== undefined && this.value.inputFieldValue.trim().length === 0) {
              return false
            }
            else return true
          }
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          subtextFontSize: "calc(1/0.90 * 12px)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },

      "step-12": {
        id: "step-12",
        name: "marketingChannels",
        type: "iconSelection",
        defaultValue: 'N/A',
        value: getPrevValue("marketingChannels", []),
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        storeFullOption: true,
        isOptional: false,
        textPosition: 'top',
        label: "What marketing channels do you use?",
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        validate: function (_val = this.value) {
          console.log("validating....", this.value)
          if (this.value.length === 0 || this.value === 'N/A')
            return false
          if (Array.isArray(this.value)) {
            let arrIncludesOther = this.value.find(it => it.inputFieldValue !== undefined)
            if (arrIncludesOther && arrIncludesOther.inputFieldValue.trim().length === 0)
              return false
            if (typeof (this.value) === "object") {
              if (this.value.showInputFieldOnClick === undefined || !this.value.showInputFieldOnClick)
                return true
              else if (this.value.showInputFieldOnClick === true && this.value.inputFieldValue.trim().length === 0)
                return false
              else
                return true
            };
          } else if (typeof this.value === 'object') {
            if (this.value.inputFieldValue !== undefined && this.value.inputFieldValue.trim().length === 0) {
              return false
            }
            else return true
          }
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        labelInternal: true,
        get enum() {
          return newEnum([
            "Social Media",
            "Websites/Blogs",
            "Email Marketing",
            "Omni-Channel Marketing",
            { displayName: "Video Marketing", subtext: "TikTok, Reels, YouTube Shorts, etc." },
            "Search Engine Optimization",
            "Influencer Marketing",
            "Podcast Marketing",
            "Word-of-Mouth Marketing",
            {
              displayName: "Other",
              id: "other-23",
              showInputFieldOnClick: true,
              placeholder: "Specify Channel",
              get inputFieldValue() {
                let _prev = getPrevValue("injuriesSustained", 'N/A');
                if (Array.isArray(_prev)) {
                  let vArrayIncOther = _prev.find(s => s.id === this.id)
                  if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                    return vArrayIncOther["inputFieldValue"];
                  return '';
                } else if (typeof (_prev) === 'object' && _prev["inputFieldValue"] !== undefined) {
                  return _prev["inputFieldValue"];
                }
                return ''
              },
            }
          ]);
        },
        imgProps: {
          height: "60px",
          width: "60px",
          mobileHeight: "50px",
          mobileWidth: "50px",
          minWidthMobile: "50px",
          minHeightMobile: "50px",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          subtextFontSize: "calc(1/0.90 * 12px)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      "step-17": {
        id: "step-17",
        name: "searchTerms",
        type: "textArea",
        placeholder: `Enter search terms you'd like to target.`,
        label: "Please list 3-5 search terms that are most important to you. (Optional)",
        labelWeight: 'bolder',
        labelFontSize: '1.17em',
        margin: '0 0 1.17em',
        value: getPrevValue("searchTerms"),
        isValid: true,
        shouldShow: false,
        labelInternal: true,
        maxlength: 500,
        rows: 5,
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
      },
      "step-20": {
        id: "step-20",
        name: "domain",
        type: "input",
        placeholder: "seocostcalc.com",
        label: "What's your website?",
        labelWeight: 'bolder',
        labelFontSize: '1.17em',
        margin: '0 0 1.17em',
        value: getPrevValue("domain",""),
        isValid: false,
        validate: function (_v = this.value) {
          return validateDomain(_v)
        },
        shouldShow: false,
        labelInternal: false,
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
      },
      "step-18": {
        id: "step-18",
        name: "seoGoals",
        width: '100%',
        maxWidth: '600px',
        type: "textArea",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        subtext: `The more detail you provide, the better the cost estimate you'll receive.  These may include, top ranking for popular search terms, web traffic, lead generation, conversion, larger web presence, savings goals vs. paid marketing (PPC, etc.), and anything else relevant to your SEO goals.`,
        placeholder: 'Enter info about your SEO goals.',
        value: getPrevValue("seoGoals"),
        margin: '0 0 1.17em',
        label: "Please describe your SEO goals.",
        labelWeight: 'bolder',
        labelFontSize: '1.17em',
        isValid: false,
        isOptional: false,
        shouldShow: false,
        labelInternal: true,
        maxlength: 500,
        rows: 10,
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        validate: function (_val = this.value) {
          if (!_val || typeof (_val) !== 'string')
            return false
          if (_val.trim().length <= 5)
            return false
          return true
        },

      },
      // "step-17": {
      //   id: "step-17",
      //   name: "technicalSeoServicesOffered",
      //   type: "iconSelection",
      //   defaultValue: 'N/A',
      //   value: getPrevValue("technicalSeoServicesOffered", []),
      //   multiSelect: true,
      //   itemsPerRow: 1,
      //   itemsPerRowMobile: 1,
      //   itemMargin: "7.5px",
      //   storeFullOption: true,
      //   isOptional: true,
      //   textPosition: 'top',
      //   label: "What, if any, technical SEO services do you need?",
      //   valueArrayKey: 'displayName',
      //   valueIsSelectedKey: 'displayName',
      //   outerContainerProps: {
      //     width: "100%",
      //     maxWidth: "600px",
      //     height: "min-content",
      //   },
      //   validate: function (_val = this.value) {
      //     console.log("validating....", this.value)
      //     if (this.value.length === 0 || this.value === 'N/A')
      //       return true
      //     if (Array.isArray(this.value)) {
      //       let arrIncludesOther = this.value.find(it => it.inputFieldValue !== undefined)
      //       if (arrIncludesOther && arrIncludesOther.inputFieldValue.trim().length === 0)
      //         return false
      //       if (typeof (this.value) === "object") {
      //         if (this.value.showInputFieldOnClick === undefined || !this.value.showInputFieldOnClick)
      //           return true
      //         else if (this.value.showInputFieldOnClick === true && this.value.inputFieldValue.trim().length === 0)
      //           return false
      //         else
      //           return true
      //       };
      //     } else if (typeof this.value === 'object') {
      //       if (this.value.inputFieldValue !== undefined && this.value.inputFieldValue.trim().length === 0) {
      //         return false
      //       }
      //       else return true
      //     }
      //     return (
      //       this.value !== "N/A" &&
      //       this.value.trim() !== "" &&
      //       this.value !== "no-answer"
      //     );
      //   },
      //   labelInternal: true,
      //   get enum() {
      //     return newEnum([
      //       // "Link-Building",
      //       // "Content Creation",
      //       "URL Structure",
      //       "Website Speed and Performance",
      //       "Responsive Design",
      //       "Content Optimization",
      //       "Structured Data Markup",
      //       {
      //         displayName: "Other/Not Sure",
      //         id: "other-17",
      //         showInputFieldOnClick: true,
      //         placeholder: "Please specify, or write 'Not Sure'",
      //         get inputFieldValue() {
      //           let _prev = getPrevValue("technicalSeoServicesOffered", 'N/A');
      //           if (Array.isArray(_prev)) {
      //             let vArrayIncOther = _prev.find(s => s.id === this.id)
      //             if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
      //               return vArrayIncOther["inputFieldValue"];
      //             return '';
      //           } else if (typeof (_prev) === 'object' && _prev["inputFieldValue"] !== undefined) {
      //             return _prev["inputFieldValue"];
      //           }
      //           return ''
      //         },
      //       }
      //     ]);
      //   },
      //   imgProps: {

      //   },
      //   tileProps: {
      //     maxWidth: "400px",
      //     maxHeight: "150px",
      //     height: "min-content",
      //     fontSize: "calc(1/0.90 * 1.0rem)",
      //     subtextFontSize: "calc(1/0.90 * 12px)",
      //     alignItems: "start",
      //     alignText: "left",
      //     gap: "0",
      //   },
      // },
      "step-19": {
        id: "step-19",
        name: "businessSize",
        type: "iconSelection",
        defaultValue: 'N/A',
        value: getPrevValue("businessSize", []),
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        storeFullOption: true,
        isOptional: true,
        textPosition: 'top',
        label: "What kind of company are you?",
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        labelInternal: true,
        get enum() {
          return newEnum([
            "B2B",
            "B2C",
            "Startup",
            "Small and Midsize Business (SMB)",
            "Large Enterprise",
          ]);
        },
        imgProps: {

        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          subtextFontSize: "calc(1/0.90 * 12px)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      "step-13": {
        id: "step-13",
        name: "contentGenerationOffered",
        type: "iconSelection",
        defaultValue: 'N/A',
        value: getPrevValue("contentGenerationOffered", []),
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        storeFullOption: true,
        isOptional: false,
        textPosition: 'top',
        label: "Does your company currently generate any of the following types of content?",
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        validate: function (_val = this.value) {
          if (this.value.length === 0 || this.value === 'N/A')
            return false
          if (Array.isArray(this.value)) {
            let arrIncludesOther = this.value.find(it => it.inputFieldValue !== undefined)
            if (arrIncludesOther && arrIncludesOther.inputFieldValue.trim().length === 0)
              return false
            if (typeof (this.value) === "object") {
              if (this.value.showInputFieldOnClick === undefined || !this.value.showInputFieldOnClick)
                return true
              else if (this.value.showInputFieldOnClick === true && this.value.inputFieldValue.trim().length === 0)
                return false
              else
                return true
            };
          } else if (typeof this.value === 'object') {
            if (this.value.inputFieldValue !== undefined && this.value.inputFieldValue.trim().length === 0) {
              return false
            }
            else return true
          }
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        labelInternal: true,
        get enum() {
          return newEnum([
            "Articles",
            "Blog Posts",
            "Press Releases",
            {
              displayName: "Other",
              id: "other-23",
              showInputFieldOnClick: true,
              placeholder: "What type of content?",
              get inputFieldValue() {
                let _prev = getPrevValue("contentGenerationOffered", []);
                if (Array.isArray(_prev)) {
                  let vArrayIncOther = _prev.find(s => s.id === this.id)
                  if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                    return vArrayIncOther["inputFieldValue"];
                  return '';
                } else if (typeof (_prev) === 'object' && _prev["inputFieldValue"] !== undefined) {
                  return _prev["inputFieldValue"];
                }
                return ''
              },
            },
            {
              displayName: "No/Not Sure",
              id: "no-13",
            }
          ]);
        },
        imgProps: {
          height: "60px",
          width: "60px",
          mobileHeight: "50px",
          mobileWidth: "50px",
          minWidthMobile: "50px",
          minHeightMobile: "50px",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          subtextFontSize: "calc(1/0.90 * 12px)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      "step-14": {
        id: "step-14",
        name: "socialMediaContentGeneration",
        type: "iconSelection",
        defaultValue: 'N/A',
        value: getPrevValue("socialMediaContentGeneration", []),
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        storeFullOption: true,
        isOptional: false,
        textPosition: 'top',
        label: "Does your company have active accounts on social media?",
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        validate: function (_val = this.value) {
          if (this.value.length === 0 || this.value === 'N/A')
            return false
          if (Array.isArray(this.value)) {
            let arrIncludesOther = this.value.find(it => it.inputFieldValue !== undefined)
            if (arrIncludesOther && arrIncludesOther.inputFieldValue.trim().length === 0)
              return false
            if (typeof (this.value) === "object") {
              if (this.value.showInputFieldOnClick === undefined || !this.value.showInputFieldOnClick)
                return true
              else if (this.value.showInputFieldOnClick === true && this.value.inputFieldValue.trim().length === 0)
                return false
              else
                return true
            };
          } else if (typeof this.value === 'object') {
            if (this.value.inputFieldValue !== undefined && this.value.inputFieldValue.trim().length === 0) {
              return false
            }
            else return true
          }
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        labelInternal: true,
        get enum() {
          return newEnum([
            "Instagram",
            "Facebook",
            "Twitter",
            "LinkedIn",
            "TikTok",
            {
              displayName: "Other",
              id: "other-23",
              showInputFieldOnClick: true,
              placeholder: "Specify Channel",
              get inputFieldValue() {
                let _prev = getPrevValue("socialMediaContentGeneration", []);
                if (Array.isArray(_prev)) {
                  let vArrayIncOther = _prev.find(s => s.id === this.id)
                  if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                    return vArrayIncOther["inputFieldValue"];
                  return '';
                } else if (typeof (_prev) === 'object' && _prev["inputFieldValue"] !== undefined) {
                  return _prev["inputFieldValue"];
                }
                return ''
              },
            },
            {
              displayName: "No",
              id: "no-13",
            }
          ]);
        },
        imgProps: {
          height: "60px",
          width: "60px",
          mobileHeight: "50px",
          mobileWidth: "50px",
          minWidthMobile: "50px",
          minHeightMobile: "50px",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          subtextFontSize: "calc(1/0.90 * 12px)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      submit: {
        id: "submit",
        name: "submit",
      },
    },

    stepOrder: getPrevValue("stepOrder", [
      // "step-19",
      // "step-16",
      "step-15",
      // "step-14",
      // "step-13",
      "step-17",
      "step-18",
      "step-20",
      "step-4",
      "submit",
    ]),
    get initialStepOrder() {
      return [
        // "step-19",
        // "step-16",
        "step-15",
        // "step-14",
        // "step-13",
        "step-17",
        "step-18",
        "step-20",
        "step-4",
        "submit",
      ];
    },
    get active() {
      return jumpStepOnLoad(this.steps, this.stepOrder);
      // return getPrevValue("active",
      //     jumpStepOnLoad(this.steps, this.stepOrder)
      // )
    },
    get party() {
      return "You";
    },
    startTime: Date.now(),
    endTime: null,
  };
  const [state, setState] = useState(_data);
  const { steps, active, stepOrder, initialStepOrder } = state;
  const [activeNew, setActiveNew] = useState(active)
  const [disabled, setDisabled] = useState(true);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const initialRender = useRef(true);
  const handleChange =
    (name, _value = null) =>
      (event) => {
        const stepId = getStep(name);
        const step = steps[stepId];
        if (name === "geographicArea" && _value) {
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [stepId]: {
                ...prev.steps[stepId],
                value: [...prev.steps[stepId].value, _value],
              },
            },
          }));
        } else if (name !== "phoneNumber" && event.target) {
          let value = event.target.value;

          //////
          const dependentSteps = Object.keys(steps).filter((_st) => {
            let _dep = steps[_st]["dependency"];
            if (!_dep) return false;
            if (typeof _dep === "string")
              return steps[_st]["dependency"] === step["name"];
            if (Array.isArray(_dep))
              return steps[_st]["dependency"].filter(
                (val) => val === step["name"]
              );
          });
          let dependentStepsObject = {};
          let newSteps = steps;
          /**
           * @type {Array}
           */
          let newStepOrder = stepOrder;
          if (dependentSteps) {
            for (let i = 0; i < dependentSteps.length; i++) {
              let dependentStep = dependentSteps[i];
              let result = steps[dependentStep].onDependencyUpdate(
                value,
                step["name"]
              );
              if (
                result["stepId"] !== undefined ||
                result["additionalValueChecks"] !== undefined
              ) {
                let updateFields = Object.fromEntries(
                  Object.entries(result).filter(
                    (it) =>
                      it[0] !== "stepId" &&
                      it[0] !== "additionalValueChecks" &&
                      it[0] !== "update"
                  )
                );
                if (Array.isArray(result["additionalValueChecks"])) {
                  let vals = {};
                  for (
                    let index = 0;
                    index < result["additionalValueChecks"].length;
                    index++
                  ) {
                    const stepName = result["additionalValueChecks"][index];
                    const additionalCheckStepId = getStep(stepName, steps);
                    if (additionalCheckStepId) {
                      let additionalCheckStep = steps[additionalCheckStepId];

                      vals[stepName] = additionalCheckStep["value"];
                    }
                  }

                  updateFields = {
                    ...updateFields,
                    ...result["update"]({ ...vals }),
                  };
                }
                newSteps[dependentStep] = {
                  ...newSteps[dependentStep],
                  ...updateFields,
                };
              }
              if (result["stepOrderChange"] !== undefined) {
                let {
                  stepOrderChange: { action, stepId: depStepId },
                } = result;
                if (
                  action === "add" &&
                  !newStepOrder.find((el) => el === depStepId)
                ) {
                  let insertionIndex = initialStepOrder.findIndex(
                    (s) => s === depStepId
                  );
                  if (insertionIndex >= 0)
                    newStepOrder.splice(insertionIndex, 0, depStepId);
                } else if (action !== "add") {
                  newStepOrder = newStepOrder.filter((it) => it !== depStepId);
                }
              }
            }
          }
          setState(function (prev) {
            return {
              ...prev,
              stepOrder: newStepOrder,
              steps: {
                ...prev.steps,
                ...newSteps,
                [stepId]: {
                  ...prev.steps[stepId],
                  value: value,
                },
              },
            };
          });
          //////
          // setState((prev) => ({
          //   ...prev,
          //   steps: {
          //     ...prev.steps,
          //     [stepId]: {
          //       ...prev.steps[stepId],
          //       value,
          //     },
          //   },
          // }));
        }
      };

  const formLength = Object.keys(stepOrder).length;
  const formatData = (flatten = true) => {
    const data = {};
    data.startTime = state["startTime"];
    data.endTime = Date.now();
    stepOrder.forEach((stepId) => {
      if (stepId === "submit") return;

      const step = steps[stepId];

      if (!flatten) {
        data[step.name] = step.value;
        if (step.name === "finalStep") {
          Object.keys(step["fields"]).forEach((fieldKey) => {
            const field = step.fields[fieldKey];
            data[field.name] = field.value;
            if (field.name === 'phoneNumber')
              data['country'] = field['country'];
          });
        }
        return;
      }
      if (step.name === "geographicArea") data["ste_name"] = step.value;
      if (step.name === "geographicAreaDetailed") {
        data["city"] = step.value.value;
        data["pla_code"] = step.value.pla_code;
        // data['svgPath'] = step.svgPath
        // data["ste_name"] = step.value.state
        return;
      }
      if (step.name === "testType") {
        data[step.name] = step.value.value;
        // data["ste_name"] = step.value.state
        return;
      }
      if (step.name === "finalStep") {
        Object.keys(step["fields"]).forEach((fieldKey) => {
          const field = step.fields[fieldKey];
          data[field.name] = field.value;
          if (field.name === 'phoneNumber')
            data['country'] = field['country'];
        });
      }
      // if (Array.isArray(step.value))
      //   data[step.name] = step.value.map((it) => handleStepValues(it, step));
      if (Array.isArray(step.value))
        data[step.name] = step.value.map((it) => {
          if (typeof (it) === 'string') {
            return (handleStepValues(it, step))
          }
          let val = it[step['valueArrayKey'] ? step['valueArrayKey'] : 'id'];
          if (val === 'Other') {
            let otherObj = step['value'].find(ob => ob.displayName === 'Other')
            if (otherObj && otherObj['inputFieldValue']) {
              return (handleStepValues(otherObj['inputFieldValue'], step))
            }
          }
          if (it)
            return (handleStepValues(val, step))
        });
      else if (typeof step.value === "string")
        data[step.name] = handleStepValues(step.value, step);
      else data[step.name] = step.value;
    });
    return data;
  };

  const handleStepValues = (it, step) => {
    if (it["id"]) {
      if (/-\d/.test(it["id"])) {
        let displayName =
          step.enum[it.id]["text"] || step.enum[it.id]["displayName"];
        return {
          ...it,
          id: it["id"].split(/-\d/)[0],
          displayName
          // displayName: toTitleCase(displayName.replace(/-/g, " ")),
        };
      } else {
        return it;
      }
    } else {
      return (step['valueArrayKey'] || step['type'] === 'input') ? it : toTitleCase(it.split(/-\d/)[0].replace(/-/g, " "), true);
    }
  };

  const submitForm = async () => {
    try {
      const data = formatData();
      console.log("data", data)
      // return
      const resData = await API.submitForm(data);
      if (window.sessionStorage.getItem("formData"))
        sessionStorage.removeItem("formData");
      if (resData) {
        // const { googleCount } = resData
        // if (googleCount) {
        if (window.dataLayer && Array.isArray(window.dataLayer)) {
          let ev = {
            'event': 'form_submission',
            'form_id': 'PRIMARY FORM',
            'form_entry': formEntry.current
          }
          //   if (googleCount)
          ev['isCAC'] = true
          window.dataLayer.push(ev)
        }
      }
      navigate("/success");
    } catch (error) {
      console.log("error", error)
      if (error.response["data"]) {
        const {
          response: {
            status,
            data: { msg },
          },
        } = error;
        navigate("/error", {
          state: { error: { status, msg }, ref: "form-submission" },
        });
      } else {
        navigate("/error", {
          state: {
            error: { status: error.response.status, msg: "An error occurred" },
            ref: "form-submission",
          },
        });
      }
    }
  };

  function hasPhoneNumber() {
    const phoneNumberStep = getStep("phoneNumber");
    if (!phoneNumberStep) return false;
    if (steps[phoneNumberStep].value === "") return false;
    return true;
  }

  useUnload((e) => {

    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      if (window.dataLayer.find(it => it['event'] === 'form_submission') === undefined) {
        window.dataLayer.push({
          'event': 'form_abandonment',
          'eventCategory': 'Form Abandonment',
          'eventAction': formAbandonment.current
        })
      }
    }
  })

  const getSelectedStates = () => {
    const stepId = getStep("geographicArea");
    if (stepId) {
      let _val = steps[stepId].value;
      return steps[stepId].value;
    }
  };

  useEffect(() => {
    const onCarriageReturn = (e) => {
      // nextStep()
      if (e.code === 'Enter') {
        console.log("enter key pressed")
        console.log(steps[active])
        nextStep()
      }
    }

    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    validateActiveStep();
    sessionStorage.setItem(
      "formData",
      JSON.stringify({ ...formatData(false), active, stepOrder })
    );
    // window.addEventListener('keydown', onCarriageReturn)
    // function cleanup() {
    //   window.removeEventListener("keydown", onCarriageReturn);
    // }
    // window.addEventListener('keyup', cleanup, { once: true })
    // return () => {
    //   window.removeEventListener('keydown', onCarriageReturn)
    // }

  }, [state]);
  useEffect(() => {
    if (Array.isArray(window.dataLayer)) {
      if (!formAbandonment.current.includes(steps[active].name)) {
        if (formAbandonment.current === '') {
          formAbandonment.current = steps[active].name
        }
        else
          formAbandonment.current = formAbandonment.current + ` > ${steps[active].name}`
        window.dataLayer.push({
          'event': 'form_engagement',
          'eventCategory': 'Form Engagement',
          'eventAction': formAbandonment.current
        });
      }
    }
  }, [active])
  // useEffect(() => {
  //   setActiveNew(active)
  //   if (!isLastStep()) {
  //     let x = document.querySelector('.content')
  //     if (x) {
  //       x.classList.add('changingStep')
  //       x.style.overflow = 'hidden'
  //     }
  //     setTimeout(() => {
  //       // setActiveNew(active)
  //       let x = document.querySelector('.content')
  //       if (x) {
  //         x.classList.remove('changingStep')
  //         x.style.overflow = 'visible'
  //       }
  //     }, 800)
  //   }

  // }, [active])

  console.log(steps[active])
  const validateActiveStep = () => {
    if (steps[active]) {
      const valid = steps[active].validate
        ? steps[active].validate(steps[active].value)
        : true;
      if (valid) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };
  const getNextStepKey = (_stepOrder = stepOrder) => {
    const activeIndex = _stepOrder.findIndex((stepId) => stepId === active);
    return activeIndex + 1;
  };
  async function nextStep() {
    console.log("next step", disabled)
    const nextStep = getNextStepKey();
    if (steps[active].onNextStep) {
      let res = steps[active].onNextStep();
      if (typeof (res) === 'boolean' && res === false) return;
    } else if (disabled) return;
    if (isLastStep()) {
      setState((prev) => ({ ...prev, active: stepOrder[nextStep] }));
      await submitForm();
    } else if (nextStep < formLength) {
      // if (!skipSetter)
      setState((prev) => ({ ...prev, active: stepOrder[nextStep] }));
      // else return stepOrder[nextStep]
    }

  }
  const getActiveIndex = (_steps = stepOrder) =>
    _steps.findIndex((stepId) => stepId === active);
  const isFirstStep = () => getActiveIndex() === 0;
  const isLastStep = () => getActiveIndex() + 1 === formLength - 1;
  const isSubmitStep = () => getActiveIndex() + 1 === formLength;


  const getStepIndex = (_stepId, _steps = stepOrder) =>
    _steps.findIndex((stepId) => stepId === _stepId);

  const getPrevStepIndex = (returnStepId = false) => {
    const _steps = Object.keys(steps);
    const activeIndex = stepOrder.findIndex((stepId) => stepId === active); //getActiveIndex(_steps)
    const stepIndex = activeIndex - 1;
    if (!returnStepId)
      return stepIndex
    else
      return stepOrder[stepIndex]
  }

  const getNextStepIndex = (returnStepId = false) => {
    const _steps = Object.keys(steps);
    const activeIndex = stepOrder.findIndex((stepId) => stepId === active); //getActiveIndex(_steps)
    const stepIndex = activeIndex + 1;
    if (!returnStepId)
      return stepIndex
    else
      return stepOrder[stepIndex]
  }
  const optionalStepsSection = getActiveIndex() >= getStepIndex("step-9") && !isLastStep() && !isSubmitStep() && getStepIndex("step-9") !== -1
  const skipToLastStep = () => {
    let jumpToIndex = stepOrder.length - 2
    if (optionalStepsSection)
      setState(prev => ({ ...prev, active: stepOrder[jumpToIndex] }))
  }

  const backStep = () => {
    let stepIndex = getPrevStepIndex()
    if (stepIndex >= 0)
      setState((prev) => ({ ...prev, active: stepOrder[stepIndex] }));
  };

  const onKeyDown = (name) => (e) => {
    const { key } = e;
    if (key === "Enter") {
      nextStep();
    } else {
      const stepId = getStep(name);
      let value = key;
      if (name === "phoneNumber") {
        const { fields } = steps[active];
        const matchingField = Object.keys(fields).find((key) => {
          const field = fields[key];

          return field.name === name;
        });

        if (matchingField) {
          let _val = steps[active].fields[matchingField].value["phoneNumber"];

          if (key === "Backspace" && isKeyReleased) {
            const _noDash = _val.replace(/\D/g, "");
            _val = _noDash.slice(0, _noDash.length - 1);
            _val = formatPhoneNumber(_val);
          } else {
            _val = _val + key.replace(/\D/g, "");
            const _noDash = _val.replace(/\D/g, "");
            _val = formatPhoneNumber(_noDash);
          }
          let { value: isValid } =
            steps[active].fields[matchingField].validate(_val);
          if (name === "phoneNumber")
            setState((prev) => ({
              ...prev,
              steps: {
                ...prev.steps,
                [active]: {
                  ...prev.steps[active],
                  fields: {
                    ...prev.steps[active].fields,
                    [matchingField]: {
                      ...prev.steps[active].fields[matchingField],
                      value: {
                        ...prev.steps[active].fields[matchingField].value,
                        phoneNumber: _val,
                      },
                      isValid,
                    },
                  },
                },
              },
            }));
        }

        // }
      }
      // setIsKeyReleased(false);
    }
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };



  const filterDuplicatesOut = (arr, val) => {
    if (typeof val === "string") {
      return arr.reduce((a, b) => {
        if (a.includes(b)) return a.filter((it) => it !== b);
        else a.push(b);
        return a;
      }, []);
    } else {
      return arr.reduce((a, b, index, array) => {
        if (a.findIndex((el) => el["id"] === b["id"]) >= 0)
          return a.filter((it) => it["id"] !== b["id"]);
        else if (!b["id"].includes("not-sure") && !(b["id"].replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase() === "no")) a.push(b);
        return a;
      }, []);
    }
  };
  const addOrRemoveGeoDetailedIfDC = (_val, newStepOrder, geoStepId, currStepId) => {
    if ((new RegExp('District of Columbia', "i")).test(_val)) {
      newStepOrder = newStepOrder.filter(step => step !== geoStepId)
    } else {
      if (!newStepOrder.includes(geoStepId) && initialStepOrder.includes(geoStepId))
        newStepOrder.splice(newStepOrder.findIndex(step => step === currStepId) + 1, 0, geoStepId)
    }
    return newStepOrder
  }

  const findStepDependency = (parentStepName) => {
    return Object.keys(steps).find((step) => {
      let _dep = steps[step]["dependency"];
      if (!_dep) return false;
      if (typeof _dep === "string")
        return steps[step]["dependency"] === parentStepName;
      if (Array.isArray(_dep))
        return steps[step]["dependency"].find(
          (val) => val === parentStepName
        );
    });
  }

  const setSvgPath = (svgPathOfState) => {
    let _svgPath = svgPathOfState.getAttribute("d");
    let _svgPathId = svgPathOfState.getAttribute("id");
    sessionStorage.setItem(
      "svgPath",
      JSON.stringify({ _svgPath, _svgPathId })
    );
  }

  const geographicAreaOnChange = (_val, svgPathOfState) => {
    let update;
    const currStepId = getStep('geographicArea')
    const step = steps[currStepId]
    if (!step)
      return
    const geoStepId = getStep('geographicAreaDetailed')
    let multiSelect = step.multiSelect || false;
    let _active = active;
    let newStepOrder = stepOrder
    newStepOrder = addOrRemoveGeoDetailedIfDC(_val, newStepOrder, geoStepId, currStepId)
    if (svgPathOfState)
      setSvgPath(svgPathOfState)
    const dependentStep = findStepDependency(step['name'])

    if (dependentStep && svgPathOfState) {
      update = steps[dependentStep].onDependencyUpdate(
        svgPathOfState,
        _val
      );
    }
    if (!multiSelect && step.validate(_val)) {
      _active = newStepOrder[getNextStepKey(newStepOrder)];
    }
    if (!update) {
      setState((prev) => ({
        ...prev,
        stepOrder: newStepOrder,
        active: _active,
        steps: {
          ...prev.steps,
          [currStepId]: {
            ...prev.steps[currStepId],
            value: _val,
          },
        },
      }));
    }
    else if (update.stepId) {
      setState((prev) => ({
        ...prev,
        stepOrder: newStepOrder,
        active: _active,
        steps: {
          ...prev.steps,
          [currStepId]: {
            ...prev.steps[currStepId],
            value: _val,
          },
          [update.stepId]: {
            ...prev.steps[update.stepId],
            ...Object.fromEntries(
              Object.entries(update).filter((it) => it[0] !== "stepId")
            ),
          },
        },
      }));
    }

  }
  const geographicAreaDetailedOnChange = (_val) => {
    const stepId = getStep('geographicAreaDetailed')
    const step = steps[stepId]
    if (!step)
      return
    let multiSelect = step.multiSelect || false;
    let _active = active;
    let newStepOrder = stepOrder
    let v = _val;
    if (typeof v == "object" && !Object.keys(v).includes("state")) {
      v = { ...v, state: step.stateName, ste_name: step.stateName };
    }
    if (!multiSelect && step.validate(_val)) {
      _active = newStepOrder[getNextStepKey(newStepOrder)];
    }
    setState((prev) => ({
      ...prev,
      active: _active,
      steps: {
        ...prev.steps,
        [step.id]: {
          ...prev.steps[step.id],
          value: v,
        },
      },
    }));
  }
  /*
  if storeFullOption is true for the step, option is passed as an object else it's a string
  */
  const onInputFieldChange = (stepId, enumId, value) => {
    if (!steps[stepId]) return;
    if (
      steps[stepId].inputFieldValue ||
      steps[stepId].storeFullOption === false
    )
      setState(function (prev) {
        return {
          ...prev,
          steps: {
            ...prev.steps,
            [stepId]: {
              ...prev.steps[stepId],
              inputFieldValue: value,
            },
          },
        };
      });
    else if (steps[stepId]["enum"][enumId].inputFieldValue !== undefined) {

      let multiSelect = steps[stepId].multiSelect || false;
      let v = value
      setState(function (prev) {
        return {
          ...prev,
          steps: {
            ...prev.steps,
            [stepId]: {
              ...prev.steps[stepId],
              enum: {
                ...prev.steps[stepId]["enum"],
                [enumId]: {
                  ...prev.steps[stepId]["enum"][enumId],
                  inputFieldValue: value,
                },
              },
              value: !multiSelect ? {
                ...prev.steps[stepId].value,
                inputFieldValue: value,
              } : prev.steps[stepId].value.map(it => {
                if (it.inputFieldValue !== undefined) {
                  return ({ ...it, inputFieldValue: v })
                }
                else return it
              }),
              // inputFieldValue: value
            },
          },
        };
      });
    }
  };
  const tiledInputOnChange = (val) => {
    let _v;
    if (typeof (val) === 'string')
      _v = [{ id: val, value: val }]
    else if (typeof (val) === 'object')
      _v = [{ id: val, value: val }]
    else {
      _v = val
    }
    console.log("_v is:", _v)
    const step = steps[active];
    console.log("step['value']", step['value'])
    setState(prev => ({
      ...prev,
      steps: {
        ...prev.steps,
        [step['id']]: {
          ...prev.steps[step['id']],
          value: filterDuplicatesOut([...val])
        }
      }
    }))
  }
  const iconSelectionOnChange = (stepId, option) => {
    const step = steps[stepId];
    if (step) {
      let storeFullOption = step.storeFullOption || false;
      let multiSelect = step.multiSelect || false;
      let currentValue = step.value;
      let newValue, arrIncludesNotSure, newValueIsNotSure;
      let additionalKeys = step.additionalKeys || [];
      let _additionalOnClick = step["onClick"] || undefined;
      let hasTextField = typeof (option) === 'object' && option.inputFieldValue !== undefined
      if (storeFullOption) {
        newValue = (({ id, displayName, ...option }) => ({
          id,
          displayName,
          ...option,
          ...Object.fromEntries(additionalKeys.map((it) => [it, option[it]])),
        }))(option);
      } else if (typeof option !== "string") {
        newValue = option[step['valueArrayKey'] ? step['valueArrayKey'] : "id"];
      } else {
        newValue = option
      }
      if (multiSelect) {
        arrIncludesNotSure =
          currentValue.findIndex((el) => {
            let v = el['id'] ? el['id'] : el
            return (v.replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase().includes('not sure') || v.replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase() === "no")
          }
          ) >= 0;
        newValueIsNotSure = newValue["id"]
          ? newValue["id"].includes("not-sure") || newValue["id"].replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase() === "no"
          : newValue.replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase().includes('not sure') || newValue.replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase() === "no";
        console.log("arrIncludesNotSure: ", arrIncludesNotSure);
        if (!arrIncludesNotSure && newValueIsNotSure) newValue = [newValue];
        else {
          newValue = filterDuplicatesOut([...currentValue, newValue], newValue);
        }
      } else {
        newValue = option;
      }
      const dependentSteps = getDependentSteps(step, steps);
      let dependentStepsObject = {};
      let newSteps = steps;
      /**
       * @type {Array}
       */
      let newStepOrder = stepOrder;
      if (dependentSteps) {
        for (let i = 0; i < dependentSteps.length; i++) {
          let dependentStep = dependentSteps[i];
          let result = steps[dependentStep].onDependencyUpdate(
            option,
            step["name"]
          );

          if (
            result["stepId"] !== undefined ||
            result["additionalValueChecks"] !== undefined
          ) {
            let updateFields = Object.fromEntries(
              Object.entries(result).filter(
                (it) =>
                  it[0] !== "stepId" &&
                  it[0] !== "additionalValueChecks" &&
                  it[0] !== "update"
              )
            );
            if (Array.isArray(result["additionalValueChecks"])) {
              let vals = {};
              for (
                let index = 0;
                index < result["additionalValueChecks"].length;
                index++
              ) {
                const stepName = result["additionalValueChecks"][index];
                const additionalCheckStepId = getStep(stepName, steps);
                if (additionalCheckStepId) {
                  let additionalCheckStep = steps[additionalCheckStepId];
                  vals[stepName] = additionalCheckStep["value"];
                }
              }
              updateFields = {
                ...updateFields,
                ...result["update"]({ ...vals }),
              };
            }
            newSteps[dependentStep] = {
              ...newSteps[dependentStep],
              ...updateFields,
            };
          }
          if (result["stepOrderChange"] !== undefined) {
            let {
              stepOrderChange: { action, stepId: depStepId },
            } = result;
            if (
              action === "add" &&
              !newStepOrder.find((el) => el === depStepId)
            ) {
              let insertionIndex = initialStepOrder.findIndex(
                (s) => s === depStepId
              );
              if (insertionIndex >= 0)
                newStepOrder.splice(insertionIndex, 0, depStepId);
            } else if (action !== "add")
              newStepOrder = newStepOrder.filter((it) => it !== depStepId);
          }
        }
      }
      let _active = active;
      if (!multiSelect && !hasTextField) {
        _active = newStepOrder[getNextStepKey(newStepOrder)];
      }
      setState(function (prev) {
        return {
          ...prev,
          stepOrder: newStepOrder,
          // active: _active,
          steps: {
            ...prev.steps,
            ...newSteps,
            [stepId]: {
              ...prev.steps[stepId],
              value: newValue,
            },
          },
        };
      });
    }
  };

  function getDependentSteps(activeStep = steps["active"], _steps = steps) {
    return Object.keys(_steps).filter((_st) => {
      let _dep = _steps[_st]["dependency"];
      if (!_dep) return false;
      if (typeof _dep === "string")
        return _steps[_st]["dependency"] === activeStep["name"];
      if (Array.isArray(_dep))
        return _steps[_st]["dependency"].filter(
          (val) => val === activeStep["name"]
        );
    });
  }
  return (
    <PageContentContainer
      marginTop="0"
      position="absolute"
      top="0"
      color="var(--primary)"
      height="100vh"
      background="radial-gradient(circle at 25% 25%, #094d89, #050027 50% )"
      // background="linear-gradient(var(--darkBlue),var(--deepBlue))"
      justifyContent="start"
      alignItems="start"
      flexDirection="row"
      padding="0 0 50px"
    >
      <ProgressBar
        active={active}
        formLength={formLength - 1}
        getActiveIndex={getActiveIndex}
        isLastStep={isLastStep()}
      />
      <FormGrid stepType={steps[active].type}>
        {
          optionalStepsSection &&
          <GridItem
            row="back-start"
            alignSelfMobile="center"
            position="fixed"
            alignSelf="end"
            zIndex="1"
            bottom="50px"
            bottomMobile="unset"
            left="50%"
            transform="translateX(-50%)"
            justifySelf="center" positionMobile="absolute" col="1 / -1"><Button
              // boxShadow="0 0 4px rgba(0,0,0,0.5)" 
              border="var(--skipToEndBorder)"
              // rightMobile="5%" 
              widthMobile="min-content"
              fontSize="11px"
              background="var(--skipToEndBgd)"
              fontWeight="400"
              fontWeightHover="500"
              color="var(--skipToEndColor)"
              borderRadius="50px"
              textTransform="uppercase"
              onClick={skipToLastStep}
              padding="5px 15px" text="Skip to End" /></GridItem>}
        {!isSubmitStep() && (
          <GridItem
            alignSelf="center"
            className="formNavigation"
            // row="1 / -1"
            gridArea="back"
          >
            <NavArrow
              isLastStep={isLastStep()}
              direction="back"
              disabled={active === stepOrder[0]}
              onClick={backStep}
              className={isLastStep() ? "hideOnDesktop" : undefined}
            />
          </GridItem>
        )}
        {steps[active] &&
          !steps[active]["labelInternal"] &&
          steps[active].label && (
            <Flexbox
              // gridCol="2" gridRow="1"
              gridArea="label"
              justifySelf="center"
              alignSelf="end"
              {...steps[active]["labelProps"]}
              maxWidth={
                steps[active] && steps[active].maxWidth
                  ? steps[active].maxWidth
                  : undefined
              }
            >
              <h3 style={{ margin: "inherit" }}>
                {steps[active] &&
                  !steps[active]["labelInternal"] &&
                  steps[active].label}
              </h3>
              {steps[active].subtextLabel && (
                <h4 style={{ margin: "inherit" }}>
                  {steps[active].subtextLabel}
                </h4>
              )}
            </Flexbox>
          )}
        <FormContentContainer stepType={steps[active].type} outerContainerProps={steps[active]?.outerContainerProps}>
          {/* {(!isFirstStep() && steps[active]) && (
            <Flexbox className="previousStep">
              <Step
                {...state}
                serverResponse={serverResponse}
                setDisabled={setDisabled}
                setServerResponse={setServerResponse}
                geographicAreaOnChange={geographicAreaOnChange}
                geographicAreaDetailedOnChange={geographicAreaDetailedOnChange}
                backStep={backStep}
                handleChange={handleChange}
                step={steps[getPrevStepIndex(true)]}
                nextStep={nextStep}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                iconSelectionOnChange={iconSelectionOnChange}
                selectedStates={getSelectedStates()}
              />
            </Flexbox>
          )} */}
          {/* {steps[active] && (
            <Step
              {...state}
              className="activeStep"
              serverResponse={serverResponse}
              setDisabled={setDisabled}
              setServerResponse={setServerResponse}
              geographicAreaOnChange={geographicAreaOnChange}
              geographicAreaDetailedOnChange={geographicAreaDetailedOnChange}
              backStep={backStep}
              handleChange={handleChange}
              step={steps[activeNew]}
              nextStep={nextStep}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              iconSelectionOnChange={iconSelectionOnChange}
              selectedStates={getSelectedStates()}
            />
          )} */}
          {steps[active] && (
            <Step
              {...state}
              className="activeStep"
              serverResponse={serverResponse}
              setDisabled={setDisabled}
              setServerResponse={setServerResponse}
              geographicAreaOnChange={geographicAreaOnChange}
              setState={setState}
              geographicAreaDetailedOnChange={geographicAreaDetailedOnChange}
              backStep={backStep}
              handleChange={handleChange}
              step={steps[active]}
              nextStep={nextStep}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              iconSelectionOnChange={iconSelectionOnChange}
              tiledInputOnChange={tiledInputOnChange}
              onInputFieldChange={onInputFieldChange}
              selectedStates={getSelectedStates()}
            />
          )}
        </FormContentContainer>
        {!isSubmitStep() && (
          <GridItem
            gridArea="next"
            alignSelf="center"
            justifySelf="end"
            className="formNavigation"
            width={isLastStep() ? "79px" : undefined}
          >
            {!isLastStep() && (
              <NavArrow
                disabled={disabled}
                onClick={nextStep}
                direction={isLastStep() ? "submit" : "next"}
              />
            )}
          </GridItem>
        )}
      </FormGrid>
    </PageContentContainer>
  );
};

export default Form;
