import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
all: unset;
background: rgba(159, 128, 255,0.25);
color: #fff;
font-family: inherit;
font-weight: 700;
font-size: 12px;
letter-spacing: 0.05em;
box-shadow: inset 0 0 7px 3px rgba(255,255,255,0.05),  0px 0px 3px 0px rgba(0, 11, 92, 0.25);
border-radius: 30px;
transition: all 0.3s ease-in;
cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
border-top: 2px solid rgba(52, 0, 209,0.3);
border-left: 2px solid rgba(52, 0, 209,0.3);
border-bottom: 2px solid rgba(0, 43, 214,0.5);
border-right: 2px solid rgba(0, 43, 214,0.5);
display: flex;
justify-content: center;
align-items: center;
border: 2px solid rgba(255,255,255,0.1);
box-sizing: border-box;
height: ${({ height = "60px" }) => height};
width: ${({ width = "100%" }) => width};
min-width: ${({ minWidth }) => minWidth};
position: ${({ position }) => position};
left: ${({ left }) => left};
right: ${({ right }) => right};
margin: ${({ margin }) => margin};
background: linear-gradient(
      45deg,
      rgba(0, 137, 158,0.25),
      rgba(0, 137, 158,0.25) 35%,
      rgba(0, 137, 158,0.75) 50%,
      rgba(0, 137, 158,0.25) 65%,
      rgba(0, 137, 158,0.25) 100%
    );
    background-size: 240% 100%;
    

&.dark{
  background: linear-gradient(
      45deg,
      rgba(84, 0, 158,0.25),
      rgba(84, 0, 158,0.25) 35%,
      rgba(84, 0, 158,0.75) 50%,
      rgba(84, 0, 158,0.25) 65%,
      rgba(84, 0, 158,0.25) 100%
    );
    /* border: none; */
    /* box-shadow: inset 0 0 7px 3px rgba(0,0,0,0.5),  0px 0px 4px 1px rgba(0, 11, 92, 0.95); */
    
    background-size: 240% 100%;
}
:hover {
    /* color: var(--formBlue2); */
    /* width: 102%; */
    /* opacity: 1; */
    background-position: 100% 0%;
    /* background-color: var(--formBlue1); */
  }
      &.hideOnDesktop{
        position: absolute;
        z-index: -1;
        visibility: hidden;
        width: 0;
        height: 0;
        display: none;
        @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            position: relative;
            z-index: unset;
            visibility: visible;
            display: block;
height: ${({ height = "60px" }) => height};
            width: ${({ width = "100%" }) => width};
            min-width: ${({ minWidth }) => minWidth};
            /* width: 0; */
            /* height: 0; */
            /* display: none; */
        }
    }
`;
const GlassButton = ({ text, ...props }) => {
  return (
    <Button {...props}>{text}</Button>
  )
}

export default GlassButton