import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Button from './Button';
import IconSelection from './IconSelection';
import { newEnum } from '../utils';

const Span = styled.span`
font-family: Orbitron;
`;

const Fieldset = styled.fieldset`
border: none;
`

const Container = styled.div`
width: 100%;
max-width: 330px;
/* background-color: #622499; */
box-shadow: inset -5px -5px 10px #3f1464, inset 5px 5px 10px #a35be3;
border-radius: 2rem;
padding: 2rem;
`

const ButtonInput = styled.input`
background-color: ${({ large }) => large ? '#0c868a' : '#43139a'};
/* box-shadow: inset -2px -2px 3px #11051b, inset 2px 2px 3px #a35be3; */
color: ${({ large }) => large ? 'black' : undefined};
/* width: ${({ large }) => large ? '100%' : '5rem'}; */
/* height: 5rem; */
padding:1rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: ${({ large }) => large ? '1.75rem' : '3.5rem'};
font-size: 14px;
font-family: ${({ large }) => large ? 'Orbitron' : undefined};
`;

const CalculatorScreen = styled.div`
background: linear-gradient(30deg,black 70%, #5f5f5f 86%, black 93%);
/* box-shadow: inset 0 0 3px rgba(5, 5, 5, 0.75), 0 0 3px rgba(5, 5, 5, 0.75); */
border-radius: 1rem;
padding:1rem;
width: 100%;
`;
const ButtonHolder = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-between;
gap: 10px;
`;

const CheckboxS = styled.div`
border: 0;
background-color: none;
input{
    display: none;
    height: 0;
    width: 0;
    border: 0;
}
label{

}
`;

const Checkbox = ({ setState, label, children, onClick }) => {
    let id = label.replaceAll(' ', '-').toLowerCase()
    return (
        <CheckboxS>
            <ButtonInput type="checkbox" id={id} name="building"
                onChange={(e) => {
                    let { checked } = e.target
                    console.log("checked:" ,checked, id)
                    if (checked)
                        setState(prev => ([...prev, id]))
                    else
                        setState(prev => prev.filter(it => it !== id))
                }}></ButtonInput>
            <label for={id}>{label}</label>
        </CheckboxS>)
}

function iconSelectionOnChange(v) {
    console.log(v)
}

const MiniForm = () => {
    const form = useRef(null)
    const [selections, setSelections] = useState([])
    let field = {
        id: "field-3",
        type: 'iconSelection',
        name: "platforms",
        isRequired: true,
        label: "Which platforms are you interested in?",
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        labelInternal: false,
        value: [],
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: true,
        storeFullOption: true,
        tileProps: {
            maxWidth: "unset",
            maxHeight: "60px",
            fontSize: "calc(1/0.90 * 13px)",
            transform: "unset",
            height: "min-content",
            subtextFontSize: "12px",
            minHeightMobile: '60px'
        },
        containerProps: {
            // maxHeight: 'unset',
            rows: 'min-content 1fr'
        },
        options: newEnum([
            "Mobile App",
            "Web",
            "Other",
            "Not Sure",
        ]),
    }
    console.log("selections", selections)
    return (
        <Container>
            <ButtonHolder>
                <CalculatorScreen><Span >What do you dream of building?</Span></CalculatorScreen>
                <form
                    ref={form}
                    onSubmit={(e) => {
                        e.preventDefault()

                        console.log("eeee", e, form.current)
                    }}>
                    {/* <IconSelection
                        label={undefined}
                        {...field}
                        value={selections}
                        onChange={
                            (v, b = null, x = null) => {
                                console.log("vvvv", v, b, x)
                                setSelections(prev => {
                                    let x = prev
                                    x.push(v)
                                    console.log("x, v")
                                    console.log(x, v)
                                    x = x.reduce((arr, b) => {
                                        let pos = arr.findIndex(el => el.id === b.id)
                                        if (pos > -1) {
                                            console.log(pos)
                                            arr = arr.splice(pos, 1)
                                        } else
                                            arr.push(b)
                                        console.log("arr", arr, b)
                                        return arr
                                    }, [])
                                    console.log("x is:", x)
                                    return x
                                })
                            }
                        }
                    /> */}
                    <Fieldset
                    // onClick={(e) => {
                    //     let nodeList = document.getElementsByName("building")
                    //     if (nodeList) {
                    //         nodeList = Array.from(nodeList)
                    //         nodeList.forEach(node => console.log(node.id, node.checked))
                    //     }
                    // }}
                    >
                        {
                            ["Mobile App", "Web App", "Something Else"].map(it => <Checkbox
                                label={it}
                                setState={setSelections}
                                onClick={(e) => setSelections(e.target.id)} />)
                        }
                    </Fieldset>
                    <Button
                    >Get Started</Button>
                </form>
                {/* <Button type="checkbox">Web App</Button>
                <Button type="checkbox">Something Else</Button>
                <Button large type="checkbox">Get Started</Button> */}
            </ButtonHolder>
        </Container>
    )
}

export default MiniForm