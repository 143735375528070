import React, { useMemo } from 'react'
import { GridWrapper } from './Form.styles'

const getStepTypeProps = (stepType) => {
    switch (stepType) {
        case 'iconSelection':
            return {
                mobilePadding: '0 20px 20px',
                gridTempAreas: "'back content next'",
                gridTempAreasMobile: "'content content''back next'",
                gridTempRowsMobile: 'minmax(275px,100%) min-content',
                alignContent: 'center', // maybe
                // gridTempRows: '1fr',
                gridTempRows: '100%', //or
            }
        case 'final-step':
            return {
                gridTempAreas: "'back content next'",
                gridTempRows: '100%',
                gridTempAreasMobile: "'content'",
                gridTempRowsMobile: '1fr',
                gridTempColsMobile: '1fr',
                gridAutoRowsMobile: "0",
                gridAutoColsMobile: '0',
                mobileGap: '0',
                gap: '0',
                maxHeight: '100%',
                mobileHeight: 'calc(100vh - var(--navBarHeight))',
                padding: '0 0 0 50px',
                mobilePadding: '0'
            }
        case 'step-intro':
            return {
                gridTempAreas: "'back content next'",
                gridTempRows: '100%',
            }
        case 'textArea':
            return {
                gridTempAreas: "'back content next'",
                gridTempAreasMobile: "'label label''content content''back next'",
                gridTempRowsMobile: 'min-content min-content min-content',
                alignContent: 'center',
                gridTempRows: 'repeat(1, min-content)',
                stepHeightMobile: 'repeat(2, min-content)',
            }
        case 'date':
            return {
                gridTempAreas: "'back label next''back content next'",
                gridTempAreasMobile: "'label label''content content''back next'",
                gridTempRowsMobile: '1fr 1fr  min-content',
                alignContent: 'center',
                gridTempRows: 'repeat(2, min-content)',
                stepHeightMobile: 'repeat(2, min-content)',
            }
        case 'dropdown':
            return {
                gridTempAreas: "'back label next''back content next'",
                gridTempAreasMobile: "'label label''content content''back next'",
                gridTempRowsMobile: '1fr 1fr min-content',
                alignContent: 'center',
                gridTempRows: 'repeat(2, min-content)',
                stepHeightMobile: 'repeat(2, min-content)',
            }
        case 'text':
            return {
                gridTempAreas: "'back content next'",
                stepWidth: '100%',
                gridTempRows: '100%'
            }
        case 'input':
            return {
                gridTempAreas: "'back label next''back content next'",
                justify: 'space-between',
                "gridTempCols": 'min-content minmax(300px, 550px) min-content',
                gridTempAreasMobile: "'label label''content content''back next'",
                gridTempRowsMobile: 'min-content min-content min-content',
                alignContent: 'center',
                gridTempRows: 'repeat(1, min-content)',
                stepHeightMobile: 'repeat(2, min-content)',
            }
        case 'TiledInput':
            return {
                gridTempAreas: "'back label next''back content next'",
                justify: 'space-between',
                "gridTempCols": 'min-content minmax(300px, 550px) min-content',
                gridTempAreasMobile: "'label label''content content''back next'",
                gridTempRowsMobile: 'min-content min-content min-content',
                alignContent: 'center',
                gridTempRows: 'repeat(2, min-content)',
                stepHeightMobile: 'repeat(2, min-content)',
            }
        case 'geographicArea':
            return {
                gridTempAreas: "'back content next'",
                gridTempAreasMobile: "'label label''content content''back next'",
                stepWidth: '100%',
                gridTempRows: '100%'
            }
        default:
            return {};
    }
}

const FormGrid = ({ children, stepType, step, activeStep, ...props }) => {
    const stepTypeProps = useMemo(() => ({ ...getStepTypeProps(stepType) }), [stepType])
    return (
        <GridWrapper
            maxHeight="calc(100vh - var(--navBarHeight))"
            overflow="hidden"
            height="100%"
            position="relative"
            margin="auto"
            padding="30px 50px"
            gap="20px"
            width="100%"
            maxWidth="100vw"
            gridTemp={{ rows: stepTypeProps['gridTempRows'] || 'min-content 1fr', columns: stepTypeProps['gridTempCols'] || 'min-content 1fr min-content' }}
            {...stepTypeProps}
            {...props}
        >{children}</GridWrapper>
    )
}

export default FormGrid