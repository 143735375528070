import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import GeographicArea from '../GeographicArea'
import Flexbox from '../Flexbox'
import AsSeenOn from '../AsSeenOn'
import useWindowHeight from '../../hooks/useWindowHeight'
import useWindowWidth from '../../hooks/useWindowWidth'

const GridWrap = styled.div`
display: grid;
width: 100%;
grid-template-rows: 1fr min-content;
height: ${({ height }) => height};
min-height: ${({ minHeight }) => minHeight};
justify-content: ${({ justify }) => justify};
    padding: ${({ padding }) => padding};
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    justify-content: ${({ justify }) => justify};
    padding: ${({ mobilePadding }) => mobilePadding};
    min-height: ${({ minHeightMobile }) => minHeightMobile};
    grid-template-rows: repeat(2,min-content);
    gap: 10px;
}
`;



const EmbeddedGeographicArea = ({ subheadingMobile, headingFontSize,
    headingFontSizeMobile, padding = "50px 0 0", mobilePadding, SVGprops, subheadingMargin, subheadingFontSize, heading = "What state did the accident happen in?", hideInput, subheading }) => {
    const windowHeight = useWindowHeight(200)
    const windowWidth = useWindowWidth(200)
    const [wHeightLocal, setWHeightLocal] = useState(windowHeight)
    const [geographicArea, setGeographicArea] = useState('')
    const navigate = useNavigate()
    const onClick = (state, htmlEl) => {
        let svgPath = htmlEl.getAttribute('d')
        let svgPathId = htmlEl.getAttribute('id')
        let previousFormData = window.sessionStorage.getItem('formData')

        if (previousFormData) {
            previousFormData = JSON.parse(previousFormData)
        } else {
            previousFormData = {}
        }
        sessionStorage.setItem('formData', JSON.stringify({ ...previousFormData, geographicArea: state }))
        navigate('/form', { state: { jumpToStepName: 'geographicAreaDetailed', svgPath, svgPathId, form_entry: 'Embedded Header' } })
        // if (previousFormData['geographicArea'] !== state)
        // sessionStorage.setItem('formData', JSON.stringify({ ...previousFormData, geographicArea: state, stateChange: previousFormData['geographicArea'] !== state }))
        // navigate('/form', { state: { jumpToStepName: 'geographicAreaDetailed', svgPath, svgPathId, geographicArea: state, stateChange: previousFormData['geographicArea'] !== state } })

    }
    return (
        <GridWrap
            column
            id="embeddedGeoArea"
            position="relative"
            width="100%" justifyContent="end" alignItems="start" padding={padding}
            height="100%"
            minHeight="100vh"
            minHeightMobile={`${wHeightLocal}px`}
            
            justifyMobile="start" mobilePadding={mobilePadding}>
            <GeographicArea
                // color="#fff"
                hideInput={hideInput}
                // stroke="#fff"
                inputPlaceHolderColor="var(--inputPlaceHolderColor)"
                subheadingFontSize={subheadingFontSize}
                headingFontSize={headingFontSize}
                headingFontSizeMobile={headingFontSizeMobile}
                geoOuterContainerProps={{
                    width: '100%',
                    display: 'grid',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '10px',
                    gapMobile: '20px',
                    justifyItems: 'center',
                    height: '100%',
                    gridTempRows: 'repeat(3,min-content)',
                    gridTempCols: '1fr',
                    gridTempRowsMobile: 'repeat(3,min-content)'

                }}
                mapContainerProps={{ width: '100%', height: '100%' }}
                SVGprops={{
                    height: '65vh',
                    maxHeight: '100%',
                    maxWidth: '90%',
                    width: '100%',
                    minHeight: '300px'
                }}
                subheadingMargin={subheadingMargin}
                subheadingMobile={subheadingMobile}
                // hoverFill="rgba(255,255,255,0.25)"
                // selectionFill="rgba(27,134,246,0.25)"
                fill="rgba(27,134,246,0)"
                heading={heading}
                subheading={subheading}
                handleChange={onClick} />
                {/* <AsSeenOn /> */}
                </GridWrap>
    )
}

export default EmbeddedGeographicArea